<template>
	<div class="content-box-div">
		<div class="filter-box">
			<a-form-model layout="inline" :key="filterKey">
				<a-form-model-item label="客户选择">
					<!-- <a-cascader style="width: 300px;"
						:fieldNames="{label: 'corp_name', value: 'corp_id', children: 'list' }" :key="corp_id"
						:defaultValue="corp_id" :options="corpList" placeholder="请选择客户" @change="corpChange" /> -->
					<a-tree-select v-model="corp_id" @change="corpChange" style="width: 300px;"
						:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" :tree-data="corpList"
						placeholder="请选择客户"
						:replaceFields="{children:'list', title:'corp_name', key:'corp_id', value: 'corp_id' }">
					</a-tree-select>
				</a-form-model-item>
				<a-form-model-item label="区域选择">
					<a-select placeholder="请选择区域" v-model="region_id" style="width: 200px">
						<a-select-option v-for="(item, index) in regionList" :key="index"
							:value="item.region_id">{{ item.region_name }}</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item label="设备名称">
					<a-input size="default" v-model="name" placeholder="输入设备名称查询">
					</a-input>
				</a-form-model-item>
				<a-form-model-item label="设备编号">
					<a-input size="default" v-model="number" placeholder="输入设备编号查询">
					</a-input>
				</a-form-model-item>
				<a-form-model-item label="日期查询">
					<a-range-picker @change="onChange" :disabledDate="disabledDate" style="width:240px;">
						<a-icon slot="suffixIcon" type="smile" />
					</a-range-picker>
				</a-form-model-item>
				<a-form-model-item>
					<div class="btns10">
						<a-button type="primary" icon="search" @click="btnClick('查询')">
							查询
						</a-button>
						<a-button type="primary" icon="sync" @click="btnClick('重置')">
							重置
						</a-button>
						<a-button type="primary" icon="reload" @click="btnClick('刷新')">
							刷新
						</a-button>
					</div>
				</a-form-model-item>
			</a-form-model>
		</div>

		<div class="ew-filter">
			<div></div>
			<div>
				<a-button type="primary" icon="plus-circle" v-if="btnIds.includes(55)" @click="cleanamount">
					在账款录入
				</a-button>
				<a-button type="primary" icon="plus-circle" v-if="btnIds.includes(130)" @click="dcClick" style="margin-left: 15px;">
					导出
				</a-button>
			</div>
		</div>
		<div class="table-box">
			<a-table rowKey="cr_id" :columns="columns" :data-source="tableData" :pagination="false">
				<div slot="status" slot-scope="text, record">
					<a-tag color="green" v-if="record.status == 1">
						已入库
					</a-tag>
					<a-tag color="red" v-if="record.status == 0">
						未入库
					</a-tag>
					<a-tag color="purple" v-if="record.status == 2">
						在账款
					</a-tag>
				</div>
				<div slot="age6" slot-scope="text, record">
					<span class="btn-span" v-if="btnIds.includes(130)" @click="serialDetail(record.cr_id)">
						冠字号导出
					</span>
					<span class="btn-span" v-if="btnIds.includes(130)" @click="tradeDetail(record.cr_id)">
						交易记录导出
					</span>
					<span class="btn-span" v-if="record.status == 0 && btnIds.includes(131)" @click="tradeStatus(record.cr_id)">
						确认入库
					</span>
				</div>
			</a-table>
			<div class="table-pagination">
				<a-pagination v-model="tablePage" @change="tabelPageChange" show-quick-jumper :total="tableTotal"
					:show-total="(total, range) => `共${total}条记录`" :page-size="10" />
			</div>
		</div>

		<a-modal title="在账款录入" :visible="modelVisible" @ok="handleOk" @cancel="handleCancel">
			<a-form-model ref="ruleForm" :label-col="{span: 6}" :wrapper-col="{span: 14}">
				<a-form-model-item label="银行单号" prop="bank">
					<a-input v-model="bank_no" placeholder="请输入银行转账单号" />
				</a-form-model-item>
			</a-form-model>
			<a-form-model ref="ruleForm" :label-col="{span: 6}" :wrapper-col="{span: 14}">
				<a-form-model-item label="转账金额" prop="amount">
					<a-input type="number" min="0.01" step="0.01" v-model="amount" placeholder="请输入银行转账金额" />
				</a-form-model-item>
			</a-form-model>
		</a-modal>

	</div>
</template>

<script>
	import http from '@/http'
	import moment from 'moment'

	const columns = [{
			title: '设备名称',
			dataIndex: 'name',
			key: 'name',
		}, {
			title: '设备编号',
			dataIndex: 'number',
			key: 'number',
		}, {
			title: '网点名称',
			dataIndex: 'node_name',
			key: 'node_name',
		},
		{
			title: '新钞袋ID',
			dataIndex: 'cashbag_sn',
			key: 'cashbag_sn',
		},
		{
			title: '旧钞袋ID',
			key: 'old_cashbag_sn',
			dataIndex: 'old_cashbag_sn',
		},
		{
			title: '新批次号',
			key: 'trade_no',
			dataIndex: 'trade_no',
		},
		{
			title: '旧批次号',
			key: 'old_trade_no',
			dataIndex: 'old_trade_no',
		}, {
			title: '存款次数',
			dataIndex: 'num',
			key: 'num',
		}, {
			title: '纸币存款',
			dataIndex: 'camount',
			key: 'camount',
		},
		{
			title: '信封存款',
			dataIndex: 'eamount',
			key: 'eamount',
		},
		{
			title: '总金额',
			key: 'amount',
			dataIndex: 'amount',
		},
		{
			title: '清机时间',
			key: 'end_time',
			dataIndex: 'end_time',
		},
		{
			title: '状态',
			dataIndex: 'status',
			key: 'status',
			scopedSlots: {
				customRender: 'status'
			},
		},
		{
			title: '操作',
			key: 'age6',
			width: 280,
			scopedSlots: {
				customRender: 'age6'
			},
		},
	];
	export default {
		data() {
			return {
				modelVisible: false,
				columns,
				corp_id: null,
				region_id: undefined,
				name: '',
				number: '',
				start_date: '',
				end_date: '',
				tableData: [],
				tableTotal: 0,
				tablePage: 1,
				corpList: [],
				corpTreeList: [],
				regionList: [],
				defaultCorp: [],
				filterKey: Math.random(),
				bank_no: '',
				amount: '',
				btnIds: localStorage.getItem('btn_ids') ? JSON.parse(localStorage.getItem('btn_ids')) : []
			};
		},
		mounted() {
			http({
				method: 'post',
				url: '/api.corp.treelist/',
				data: {}
			}).then((res) => {
				this.corpList = res.data
				let corpTreeList = {}

				function forData(data, ids) {
					data.forEach((v) => {
						corpTreeList[v.corp_name + v.corp_id] = [...ids, v.corp_id]
						if (v.list.length) {
							forData(v.list, [...ids, v.corp_id])
						}
					})
				}
				res.data.forEach((v, i) => {
					corpTreeList[v.corp_name + v.corp_id] = v.corp_id
					if (v.list.length) {
						forData(v.list, [v.corp_id])
					}
					if (i == 0) {
						let defaultCorp = {
							...corpTreeList
						}
						let keys = Object.keys(defaultCorp)
						this.defaultCorp = v.corp_id
					}
				})
				this.corpTreeList = corpTreeList
				this.corp_id = this.defaultCorp
				this.getTable()
				this.getRegion()
			})
		},
		methods: {
			getRegion() {
				http({
					method: 'post',
					url: '/api.region.treelist/',
					data: {
						corp_id: this.corp_id
					}
				}).then((res) => {
					if (res.code == 200) {
						this.regionList = res.data
						this.getTable()
					}
				})
			},
			disabledDate(current) {
				return current && current > moment().endOf('day')
			},
			onChange(data) {
				this.start_date = moment(data[0]).format('YYYY-MM-DD')
				this.end_date = moment(data[1]).format('YYYY-MM-DD')
			},
			handleSubmit(e) {},
			corpChange(data) {
				this.corp_id = data
				this.region_id = undefined
				this.getRegion()
			},
			chcorpChange(data) {
				this.form.corp_id = data[data.length - 1]
			},
			tabelPageChange(page) {
				this.tablePage = page
				this.getTable()
			},
			btnClick(type) {
				if (type == '查询') {
					this.getTable()
				} else if (type == '重置') {
					this.corp_id = this.defaultCorp
					this.name = ''
					this.number = ''
					this.start_date = ''
					this.end_date = ''
					this.region_id = undefined
					this.tablePage = 1
					this.filterKey = Math.random()
					this.getTable()
				} else if (type == '刷新') {
					this.getTable()
				}
			},
			getTable() {
				http({
					method: 'post',
					url: '/api.deposit.cleanlist/',
					data: {
						page: this.tablePage,
						name: this.name,
						number: this.number,
						start_date: this.start_date,
						end_date: this.end_date,
						corp_id: this.corp_id,
						region_id: this.region_id == null ? 0 : this.region_id
					}
				}).then((res) => {
					this.tableData = res.data.list
					this.tableTotal = res.data.total_count
				})
			},
			dcClick() {
				http({
					method: 'post',
					url: '/api.account.clean.export/',
					data: {
						name: this.name,
						number: this.number,
						start_date: this.start_date,
						end_date: this.end_date,
						corp_id: this.corp_id,
						region_id: this.region_id == null ? 0 : this.region_id
					}
				}).then((res) => {
					if (res.code == '200') {
						window.open(res.data)
					} else {
						if (res.code != '201') {
							this.$message.error(res.message);
						}
					}
				})
			},
			tradeStatus(cr_id) {
				this.$confirm({
					title: '是否确认此笔清机金额已入库？',
					onOk: () => {
						http({
							method: 'post',
							url: '/api.deposit.cleanstatus/',
							data: {
								cr_id: cr_id
							}
						}).then((res) => {
							if (res.code == "200") {
								this.$message.success('入库操作成功');
								this.getTable()
							} else {
								this.$message.warning(res.message);
							}
						})
					},
					onCancel() {
						console.log('Cancel');
					},
				});
			},
			//导出当前记录冠字号
			serialDetail(id) {
				http({
					method: 'post',
					url: '/api.account.serial.list.export/',
					data: {
						cr_id: id
					}
				}).then((res) => {
					if (res.code == '200') {
						window.open(res.data)
					} else {
						if (res.code != '201') {
							this.$message.error(res.message);
						}
					}
				})
			},
			//导出当前清机记录的所有交易记录
			tradeDetail(id) {
				http({
					method: 'post',
					url: '/api.account.trade.list.export/',
					data: {
						cr_id: id
					}
				}).then((res) => {
					if (res.code == '200') {
						window.open(res.data)
					} else {
						if (res.code != '201') {
							this.$message.error(res.message);
						}
					}
				})
			},
			cleanamount() {
				if (this.region_id == undefined || this.region_id==null || this.region_id==0){
					this.$message.error('请先选择需要处理账款的区域');
					return
				}
				this.bank_no = ''
				this.amount = 0
				this.modelVisible = true;
			},
			handleOk() {
				if (!this.bank_no) {
					this.$message.error('请先输入银行汇款单号');
					return
				}
				if (this.amount<=0) {
					this.$message.error('请先输入银行汇款金额');
					return
				}
				let self = this
				this.$confirm({
					title: '是否确认选择区域、银行汇款单号与金额一致?',
					onOk() {
						http({
							method: 'post',
							url: '/api.deposit.cleanamount/',
							data: {
								bank_no: self.bank_no,
								amount: self.amount,
								region_id: self.region_id
							}
						}).then((res) => {
							if (res.code == "200") {
								self.$message.success('操作成功');
								self.getTable()
								self.modelVisible = false;
							} else {
								self.$message.warning(res.message);
							}
						})
					},
					onCancel() {
						console.log('Cancel');
					},
					class: 'test',
				});
			},
			handleCancel() {
				this.modelVisible = false;
			},
		},
	};
</script>

<style lang="less" scoped>
	.table-pagination {
		text-align: right;
		padding: 20px 0px;
	}

	.filter-box {
		padding: 20px;
	}

	.btns10 {
		margin-left: 30px;

		button {
			margin-right: 10px;
		}
	}

	.table-box {
		padding: 0px 20px;
	}

	.ew-filter {
		display: flex;
		justify-content: space-between;
		padding: 0px 20px;
		padding-bottom: 20px;
		align-items: center;
	}
</style>