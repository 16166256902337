<template>
	<div class="content-box-div">
		<div class="filter-box">
			<a-form-model layout="inline">
				<a-form-model-item label="客户选择">
					<!-- <a-cascader style="width: 300px;"
						:fieldNames="{label: 'corp_name', value: 'corp_id', children: 'list' }" :key="corp_id"
						:defaultValue="corp_id" :options="corpList" placeholder="请选择客户" @change="corpChange" /> -->
					<a-tree-select v-model="corp_id" @change="corpChange" style="width: 300px;"
						:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" :tree-data="corpList"
						placeholder="请选择客户"
						:replaceFields="{children:'list', title:'corp_name', key:'corp_id', value: 'corp_id' }">
					</a-tree-select>
				</a-form-model-item>
				<a-form-model-item>
					<div class="btns10">

						<a-button type="primary" icon="search" @click="btnClick('查询')">
							查询
						</a-button>
						<a-button type="primary" icon="sync" @click="btnClick('重置')">
							重置
						</a-button>
						<a-button type="primary" icon="reload" @click="btnClick('刷新')">
							刷新
						</a-button>
					</div>
				</a-form-model-item>
			</a-form-model>
		</div>

		<div class="ew-filter">
			<div></div>
			<a-button v-if="btnIds.includes(136)" type="primary" @click="modelVisible = true" icon="plus-circle">
				添加任务
			</a-button>
		</div>

		<div class="table-box">
			<a-table :columns="columns" :data-source="tableData" :pagination="false">
				<a slot="name" slot-scope="text">{{ text }}</a>
				<div slot="age6" slot-scope="text, record">
					<span class="btn-span"  v-if="btnIds.includes(137)" @click="edit(record)">
						编辑
					</span>
					<a-popconfirm v-if="btnIds.includes(138)" placement="top" ok-text="确定" cancel-text="取消" @confirm="remove(record.task_id)">
						<template slot="title">
							<p>是否确定删除该任务？</p>
						</template>
						<span class="btn-span btn-span-delete">
							删除
						</span>
					</a-popconfirm>
				</div>
			</a-table>
			<div class="table-pagination">
				<a-pagination v-model="tablePage" @change="tabelPageChange" show-quick-jumper :total="tableTotal"
					:show-total="(total, range) => `共${total}条记录`" :page-size="10" />
			</div>
		</div>

		<a-modal :title="!form.task_id ? '添加任务' :'编辑任务'" :visible="modelVisible" @ok="handleOk" @cancel="handleCancel">
			<a-form-model :key="formKey" ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol"
				:wrapper-col="wrapperCol">
				<a-form-model-item label="所属客户" prop="corp_id">
					<a-tree-select v-model="form.corp_id" @change="chcorpChange" style="width: 100%;"
						:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" :tree-data="corpList"
						placeholder="请选择所属客户"
						:replaceFields="{children:'list', title:'corp_name', key:'corp_id', value: 'corp_id' }">
					</a-tree-select>
				</a-form-model-item>
				<a-form-model-item label="任务类型" prop="type">
					<a-select ref="select" v-model:value="form.type" style="width: 100%">
						<a-select-option :value="1">当日日结</a-select-option>
						<a-select-option :value="2">数据导出</a-select-option>
						<a-select-option :value="3">审核提醒</a-select-option>
						<a-select-option :value="4">存款审核</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item label="优先级" prop="priorit">
					<a-input-number id="inputNumber" v-model="form.priorit" style="width: 100%" :min="1" :max="99" />
				</a-form-model-item>
				<a-form-model-item label="执行方式" prop="priorit">
					<a-radio-group v-model="form.is_real_time" @change="onChange">
					     <a-radio :value="1">
					       实时
					     </a-radio>
					     <a-radio :value="0">
					       定时
					     </a-radio>
					   </a-radio-group>
				</a-form-model-item>
				<a-form-model-item label="执行时间" v-if="form.is_real_time == 0" prop="export_time">
					<a-time-picker v-model="form.export_time" style="width: 100%;" format="HH:mm:ss">
					</a-time-picker>
				</a-form-model-item>
				
			</a-form-model>
		</a-modal>
	</div>
</template>

<script>
	import http from '@/http'
	import moment from 'moment'
	const columns = [{
			title: '所属客户',
			dataIndex: 'corp_name',
			key: 'corp_name',
		},
		{
			title: '任务类型',
			key: 'type_text',
			dataIndex: 'type_text',
		},
		{
			title: '执行时间',
			key: 'do_time',
			dataIndex: 'do_time',
		},
		{
			title: '优先级',
			key: 'priorit',
			dataIndex: 'priorit',
		},
		{
			title: '操作',
			key: 'age6',
			width: 250,
			scopedSlots: {
				customRender: 'age6'
			},
		},
	];
	export default {
		data() {
			return {
				modelVisible: false,
				columns,
				labelCol: {
					span: 6
				},
				wrapperCol: {
					span: 14
				},
				other: '',
				form: {
					corp_id: null,
					type: 1,
					export_time: moment('00:00', 'HH:mm:ss'),
					task_id: 0,
					priorit: 1,
					is_real_time: 1
				},
				rules: {
					type: [{
						required: true,
						message: '请选择任务类型',
						trigger: 'change'
					}],
					corp_id: [{
						required: true,
						message: '请选择所属客户',
						trigger: 'change'
					}],
					export_time: [{
						required: true,
						message: '请选择执行时间',
						trigger: 'change'
					}],
					priorit: [{
						required: true,
						message: '请输入优先级'
					}],
				},

				corp_id: null,
				tableData: [],
				tableTotal: 0,
				tablePage: 1,
				corpList: [],
				corpTreeList: [],
				defaultCorp: [],
				formKey: Math.random(),
				
				btnIds: localStorage.getItem('btn_ids') ? JSON.parse(localStorage.getItem('btn_ids')) : []
			};
		},
		mounted() {
			http({
				method: 'post',
				url: '/api.corp.treelist/',
				data: {}
			}).then((res) => {
				this.corpList = res.data
				let corpTreeList = {}

				function forData(data, ids) {
					data.forEach((v) => {
						corpTreeList[v.corp_name + v.corp_id] = [...ids, v.corp_id]
						if (v.list.length) {
							forData(v.list, [...ids, v.corp_id])
						}
					})
				}
				res.data.forEach((v, i) => {
					corpTreeList[v.corp_name + v.corp_id] = v.corp_id
					if (v.list.length) {
						forData(v.list, [v.corp_id])
					}
					if (i == 0) {
						let defaultCorp = {
							...corpTreeList
						}
						let keys = Object.keys(defaultCorp)
						this.defaultCorp = v.corp_id
					}
				})
				this.corpTreeList = corpTreeList
				this.corp_id = this.defaultCorp
				this.getTable()
			})
		},
		methods: {
			corpChange(data) {
				this.corp_id = data
			},
			chcorpChange(data) {
				this.form.corp_id = data
			},
			btnClick(type) {
				if (type == '查询') {
					this.getTable()
				} else if (type == '重置') {
					this.corp_id = this.defaultCorp
					this.tablePage = 1
					this.getTable()
				} else if (type == '刷新') {
					this.getTable()
				}
			},
			getTable() {
				http({
					method: 'post',
					url: '/api.system.task.list/',
					data: {
						page: this.tablePage,
						corp_id: this.corp_id
					}
				}).then((res) => {
					this.tableData = res.data.list
					this.tableTotal = res.data.total_count
				})
			},
			tabelPageChange(page) {
				this.tablePage = page
				this.getTable()
			},
			remove(id) {
				http({
					method: 'post',
					url: '/api.system.task.del/',
					data: {
						task_id: id,
					}
				}).then((res) => {
					if (res.code == "200") {
						this.$message.success('删除任务成功');
						this.getTable()
					} else {
						this.$message.warning(res.message);
					}
				})
			},
			edit(data) {
				let cp = this.corpTreeList[data.corp_name + data.corp_id]
				this.form = {
					...data,
					corp_id: typeof cp == 'number' ? cp : cp[cp.length - 1],
					task_id: data.task_id,
					export_time: data.is_real_time == 1 ? moment('00:00:00', 'HH:mm:ss') : moment(data.ex_time, 'HH:mm:ss'),
					is_real_time: data.is_real_time,
				}
				this.modelVisible = true;
				this.formKey = Math.random()
			},

			handleOk() {
				this.$refs.ruleForm.validate(valid => {
					if (valid) {
						let newData = {
							...this.form,
						}
						delete newData.corp_name
						delete newData.do_time
						delete newData.add_time
						delete newData.type_text
						if (typeof newData.corp_id == 'object') {
							newData.corp_id = newData.corp_id[newData.corp_id.length - 1]
						}
						newData.ex_time = newData.is_real_time == 1 ? '' : moment(newData.export_time).format('HH:mm:ss')
						delete newData.export_time
						http({
							method: 'post',
							url: '/api.system.task.add/',
							data: newData
						}).then((res) => {
							if (res.code == "200") {
								if (this.form.task_id) {
									this.$message.success('编辑任务成功');
								} else {
									this.$message.success('添加任务成功');
								}
								this.getTable()
								this.modelVisible = false;
								this.initFormData()
							} else {
								this.$message.warning(res.message);
							}
						})
					} else {
						return false;
					}
				});

			},
			handleCancel() {
				this.modelVisible = false;
				this.initFormData()
			},
			initFormData() {
				this.form = {
					corp_id: null,
					type: 1,
					export_time: moment('00:00:00', 'HH:mm:ss'),
					task_id: 0,
					priorit: 1,
					is_real_time: 1
				}
				this.formKey = Math.random()
			},
		},
	};
</script>

<style lang="less" scoped>
	.table-pagination {
		text-align: right;
		padding: 20px 0px;
	}

	.filter-box {
		padding: 20px;
	}

	.btns10 {
		margin-left: 30px;

		button {
			margin-right: 10px;
		}
	}

	.table-box {
		padding: 0px 20px;
	}

	.ew-filter {
		display: flex;
		justify-content: space-between;
		padding: 0px 20px;
		padding-bottom: 20px;
		align-items: center;
	}
</style>