<template>
	<div class="content-box-div">
		<!-- <div class="filter-box">
			<a-form-model layout="inline">
				<a-form-model-item label="菜单名称">
					<a-input size="default" v-model="formInline.user" placeholder="输入关键字查询">
					</a-input>
				</a-form-model-item>
				<a-form-model-item>
					<div class="btns10">
						<a-button type="primary" icon="search">
							查询
						</a-button>
						<a-button type="primary" icon="sync">
							重置
						</a-button>
						<a-button type="primary" icon="reload">
							刷新
						</a-button>
					</div>
				</a-form-model-item>
			</a-form-model>
		</div> -->

		<div class="ew-filter" style="padding-top: 20px;">
			<div></div>
			<a-button v-if="btnIds.includes(73)" type="primary" @click="modelVisible = true" icon="plus-circle">
				添加
			</a-button>
		</div>

		<div class="table-box">
			<a-table :columns="columns" :data-source="tableData" rowKey="router_id" childrenColumnName="list" :pagination="false">
				<a slot="name" slot-scope="text">{{ text }}</a>
				<div slot="is_open" slot-scope="text, record">
					<a-tag color="blue" v-if="record.is_open == 1">
						展开
					</a-tag>
					<a-tag color="orange" v-if="record.is_open == 0">
						闭合
					</a-tag>
				</div>
				<div slot="is_menu" slot-scope="text, record">
					<a-tag color="blue" v-if="record.is_menu == 1">
						显示
					</a-tag>
					<a-tag color="orange" v-if="record.is_menu == 0">
						不显示
					</a-tag>
				</div>
				<div slot="age6" slot-scope="text, record">
					<span class="btn-span" v-if="btnIds.includes(73)" @click="addParent(record)">
						添加子级 {{record.router_id}}
					</span>
					<span class="btn-span" v-if="btnIds.includes(74)" @click="edit(record)">
						编辑
					</span>
					<a-popconfirm placement="top" v-if="btnIds.includes(75)" ok-text="确定" cancel-text="取消" @confirm="remove(record.router_id)">
						<template slot="title">
							<p>是否确定删除该菜单？</p>
						</template>
						<span class="btn-span btn-span-delete">
							删除
						</span>
					</a-popconfirm>
				</div>
			</a-table>
		</div>


		<a-modal :title="form.router_id == 0 ? '添加' :'编辑'" :visible="modelVisible" @ok="handleOk"
			@cancel="handleCancel">
			<a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol"
				:key="formKey">
				<a-form-model-item v-if="form.parent_name" label="上级菜单" prop="router_name">
					<a-input v-model="form.parent_name" placeholder="请输入菜单名称" disabled />
				</a-form-model-item>

				<a-form-model-item label="菜单名称" prop="router_name">
					<a-input v-model="form.router_name" placeholder="请输入菜单名称" />
				</a-form-model-item>
				
				<a-form-model-item label="菜单模块">
					<a-input v-model="form.router_en" placeholder="请输入菜单模块" />
				</a-form-model-item>
				
				<a-form-model-item label="菜单ICON">
					<a-input v-model="form.icon" placeholder="请输入菜单ICON" />
				</a-form-model-item>

				<a-form-model-item label="路由地址" prop="router">
					<a-input v-model="form.router" placeholder="请输入路由地址" />
				</a-form-model-item>

				<a-form-model-item label="文件路径" prop="component">
					<a-input v-model="form.component" placeholder="请输入文件路径" />
				</a-form-model-item>
				
			
				<a-form-model-item label="是否展开">
					<a-radio-group v-model="form.is_open">
						<a-radio :value="1">
							展开菜单
						</a-radio>
						<a-radio :value="0">
							闭合菜单
						</a-radio>
					</a-radio-group>
				</a-form-model-item>

				<a-form-model-item label="是否显示">
					<a-radio-group v-model="form.is_menu">
						<a-radio :value="1">
							显示菜单
						</a-radio>
						<a-radio :value="0">
							隐藏菜单
						</a-radio>
					</a-radio-group>
				</a-form-model-item>

				<a-form-model-item label="菜单排序">
					<a-input-number placeholder="请输入菜单排序" v-model="form.sort_val" :min="1" style="width: 100%;" />
				</a-form-model-item>
			</a-form-model>
		</a-modal>
	</div>
</template>

<script>
	import http from '@/http'
	const columns = [{
			title: '菜单名称',
			dataIndex: 'router_name',
			key: 'router_name',
		},
		{
			title: '路由地址',
			dataIndex: 'router',
			key: 'router',
		},
		{
			title: '文件路径',
			key: 'component',
			dataIndex: 'component',
		},
		{
			title: '排序',
			key: 'sort_val',
			dataIndex: 'sort_val',
		},
		{
			title: '是否展开',
			key: 'is_open',
			dataIndex: 'is_open',
			scopedSlots: {
				customRender: 'is_open'
			},
		},
		{
			title: '菜单显示',
			key: 'is_menu',
			dataIndex: 'is_menu',
			scopedSlots: {
				customRender: 'is_menu'
			},
		},
		{
			title: '操作',
			key: 'age6',
			width: 250,
			scopedSlots: {
				customRender: 'age6'
			},
		},
	];

	export default {
		data() {
			return {
				modelVisible: false,
				columns,
				labelCol: {
					span: 6
				},
				wrapperCol: {
					span: 14
				},
				other: '',
				form: {
					router_name: '',
					router: '',
					component: '',
					is_open: 0,
					is_menu: 1,
					sort_val: 1,
					router_id: 0,
					parent_id: 0,
					is_enable: 1,
					icon: '',
					router_en: ''
				},
				rules: {
					router_name: [{
						required: true,
						message: '请输入菜单名称',
						trigger: 'blur'
					}],
					router: [{
						required: true,
						message: '请输入路由地址',
						trigger: 'blur'
					}],
					component: [{
						required: true,
						message: '请输入文件路径',
						trigger: 'blur'
					}],
				},

				// 表格
				tableData: [],
				formKey: Math.random(),
				
				btnIds: localStorage.getItem('btn_ids') ? JSON.parse(localStorage.getItem('btn_ids')) : []
			};
		},
		mounted() {
			this.getTable()
		},
		methods: {
			handleSubmit(e) {
			},

			getTable() {
				http({
					method: 'post',
					url: '/api.router.list/',
					data: {
						page: 1,
					}
				}).then((res) => {
					this.tableData = res.data
				})
			},
			remove(id) {
				http({
					method: 'post',
					url: '/api.router.del/',
					data: {
						router_id: id,
					}
				}).then((res) => {
					if (res.code == "200") {
						this.$message.success('删除菜单成功');
						this.getTable()
					} else {
						this.$message.warning(res.message);
					}
				})
			},
			edit(data) {
				this.form = {
					...data
				}
				this.modelVisible = true;
			},
			addParent(data) {
				this.form = {
					router_name: '',
					router: '',
					component: '',
					is_open: 0,
					is_menu: 1,
					sort_val: 1,
					router_id: 0,
					parent_id: data.router_id,
					parent_name: data.router_name,
					is_enable: 1,
					icon: '',
					router_en: ''
				}
				this.modelVisible = true
			},
			handleOk() {
				this.$refs.ruleForm.validate(valid => {
					if (valid) {
						let newData = {
							...this.form
						}
						delete newData.parent_name
						delete newData.list
						http({
							method: 'post',
							url: '/api.router.add/',
							data: newData
						}).then((res) => {
							if (res.code == "200") {
								if (this.form.router_id) {
									this.$message.success('编辑菜单信息成功');
								} else {
									this.$message.success('添加菜单成功');
								}
								this.getTable()
								this.modelVisible = false;
								this.initFormData()
							} else {
								this.$message.warning(res.message);
							}
						})
					} else {
						return false;
					}
				});

			},
			handleCancel() {
				this.modelVisible = false;
				this.initFormData()
			},
			initFormData() {
				this.form = {
					router_name: '',
					router: '',
					component: '',
					is_open: 0,
					is_menu: 1,
					sort_val: 1,
					router_id: 0,
					parent_id: 0,
					is_enable: 1,
					icon: '',
					router_en: ''
				}
				this.formKey = Math.random()
			},

		},
	};
</script>

<style lang="less" scoped>
	.table-pagination {
		text-align: right;
		padding: 20px 0px;
	}

	.filter-box {
		padding: 20px;
	}

	.btns10 {
		margin-left: 30px;

		button {
			margin-right: 10px;
		}
	}

	.table-box {
		padding: 0px 20px;
	}

	.ew-filter {
		display: flex;
		justify-content: space-between;
		padding: 0px 20px;
		padding-bottom: 20px;
		align-items: center;
	}
</style>
