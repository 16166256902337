<template>
	<div class="content-box-div">
		<div class="filter-box">
			<a-form-model layout="inline">
				<a-form-model-item label="SN号">
					<a-input size="default" v-model="snumber" placeholder="输入SN号查询">
					</a-input>
				</a-form-model-item>
				<a-form-model-item label="授权码">
					<a-input size="default" v-model="auth_code" placeholder="输入授权码查询">
					</a-input>
				</a-form-model-item>
				<a-form-model-item>
					<div class="btns10">

						<a-button type="primary" icon="search" @click="btnClick('查询')">
							查询
						</a-button>
						<a-button type="primary" icon="sync" @click="btnClick('重置')">
							重置
						</a-button>
						<a-button type="primary" icon="reload" @click="btnClick('刷新')">
							刷新
						</a-button>
					</div>
				</a-form-model-item>
			</a-form-model>
		</div>

		<div class="ew-filter">
			<div></div>
			<a-button v-if="btnIds.includes(114)" type="primary" @click="modelVisible = true" icon="plus-circle">
				添加
			</a-button>
		</div>

		<div class="table-box">
			<a-table :columns="columns" :data-source="tableData" :pagination="false">
				<a slot="name" slot-scope="text">{{ text }}</a>
				<div slot="type" slot-scope="text, record">
					<a-tag color="cyan" v-if="record.type == 1">
						动态密码锁
					</a-tag>
					<a-tag color="green" v-if="record.type == 2">
						离线密码锁
					</a-tag>
				</div>
				<div slot="status" slot-scope="text, record">
					<a-tag color="blue" v-if="record.status == 0">
						启动
					</a-tag>
					<a-tag color="orange" v-if="record.status == 1">
						禁用
					</a-tag>
				</div>
				<div slot="age6" slot-scope="text, record">
					<span class="btn-span" v-if="btnIds.includes(115)" @click="edit(record)">
						编辑
					</span>
					<span class="btn-span btn-span-delete" v-if="record.status == 0 && btnIds.includes(115)" @click="lockChange(record.status, record.lock_id)">
						禁用
					</span>
					<span class="btn-span btn-span-delete" v-if="record.status == 1 && btnIds.includes(115)" @click="lockChange(record.status, record.lock_id)">
						启动
					</span>
					<a-popconfirm v-if="btnIds.includes(116)" placement="top" ok-text="确定" cancel-text="取消" @confirm="remove(record.lock_id)">
						<template slot="title">
							<p>是否确定删除该锁具？</p>
						</template>
						<span class="btn-span btn-span-delete">
							删除
						</span>
					</a-popconfirm>
				</div>
			</a-table>
			<div class="table-pagination">
				<a-pagination v-model="tablePage" @change="tabelPageChange" show-quick-jumper :total="tableTotal"
				:show-total="(total, range) => `共${total}条记录`"	:page-size="10" />
			</div>
		</div>

		<a-modal :title="form.lock_id ? '编辑' : '添加'" :visible="modelVisible" @ok="handleOk" @cancel="handleCancel">
			<a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
				<a-form-model-item label="锁具类型"  prop="type">
					<a-radio-group v-model="form.type">
						<a-radio :value="1">
							动态密码锁
						</a-radio>
						<a-radio :value="2">
							离线密码锁
						</a-radio>
					</a-radio-group>
				</a-form-model-item>	
				<a-form-model-item label="客户号" prop="cnumber">
					<a-input v-model="form.cnumber" placeholder="请输入客户号" />
				</a-form-model-item>
				<a-form-model-item label="编号" prop="number">
					<a-input v-model="form.number" placeholder="请输入编号" />
				</a-form-model-item>
				<a-form-model-item label="SN码" prop="snumber">
					<a-input v-model="form.snumber" placeholder="请输入SN码" />
				</a-form-model-item>
				<a-form-model-item label="授权码" prop="auth_code">
					<a-input v-model="form.auth_code" placeholder="请输入授权码" />
				</a-form-model-item>
			</a-form-model>
		</a-modal>
	</div>
</template>

<script>
	import http from '@/http'
	
	const columns = [{
			title: '编号',
			dataIndex: 'number',
			key: 'number',
		},
		{
			title: '客户号',
			dataIndex: 'cnumber',
			key: 'cnumber',
		},
		{
			title: '锁具类型',
			dataIndex: 'type',
			key: 'type',
			scopedSlots: {
				customRender: 'type'
			},
		},
		{
			title: 'SN号',
			dataIndex: 'snumber',
			key: 'snumber',
		},
		{
			title: '授权码',
			dataIndex: 'auth_code',
			key: 'auth_code',
		},
		{
			title: '剩余电量',
			dataIndex: 'electricity',
			key: 'electricity',
		},
		{
			title: '状态',
			dataIndex: 'status',
			key: 'status',
			scopedSlots: {
				customRender: 'status'
			},
		},
		{
			title: '操作',
			key: 'age6',
			width: 250,
			scopedSlots: {
				customRender: 'age6'
			},
		},
	];
	export default {
		data() {
			return {
				modelVisible: false,
				columns,
				labelCol: {
					span: 6
				},
				wrapperCol: {
					span: 14
				},
				other: '',
				form: {
					lock_id: 0,
					type: 1,
					cnumber: '',
					number: '',
					snumber: '',
					auth_code: '',
				},
				rules: {
					type: [{
						required: true,
						message: '请选择类型',
						trigger: 'change'
					}],
					cnumber: [{
						required: true,
						message: '请输入客户号',
						trigger: 'blur'
					}],
					number: [{
						required: true,
						message: '请输入编号',
						trigger: 'blur'
					}],
					snumber: [{
						required: true,
						message: '请输入SN码',
						trigger: 'blur'
					}],
					auth_code: [{
						required: true,
						message: '请输入授权码',
						trigger: 'blur'
					}],
				},

				snumber: '',
				auth_code: '',
				tableData: [],
				tableTotal: 0,
				tablePage: 1,
				
				btnIds: localStorage.getItem('btn_ids') ? JSON.parse(localStorage.getItem('btn_ids')) : []
			};
		},
		mounted() {
			this.getTable()
		},
		methods: {
			btnClick(type) {
				if (type == '查询') {
					this.getTable()
				} else if (type == '重置') {
					this.snumber = ''
					this.auth_code = ''
					this.tablePage = 1
					this.getTable()
				} else if (type == '刷新') {
					this.getTable()
				}
			},
			getTable() {
				http({
					method: 'post',
					url: '/api.device.lock.list/',
					data: {
						snumber: this.snumber,
						auth_code: this.auth_code,
						page: this.tablePage
					}
				}).then((res) => {
					this.tableData = res.data.list
					this.tableTotal = res.data.total_count
				})
			},
			tabelPageChange(page) {
				this.tablePage = page
				this.getTable()
			},
			remove(id) {
				http({
					method: 'post',
					url: '/api.device.lock.del/',
					data: {
						lock_id: id,
					}
				}).then((res) => {
					if (res.code == "200") {
						this.$message.success('删除锁具成功');
						this.getTable()
					} else {
						this.$message.warning(res.message);
					}
				})
			},
			edit(data) {
				this.form = {
					...data
				}
				this.modelVisible = true;
			},

			handleOk() {
				this.$refs.ruleForm.validate(valid => {
					if (valid) {
						let newData = {
							...this.form
						}
						delete newData.status
						delete newData.device_id
						delete newData.electricity
						http({
							method: 'post',
							url: '/api.device.lock.add/',
							data: newData
						}).then((res) => {
							if (res.code == "200") {
								if (this.form.lock_id) {
									this.$message.success('编辑锁具信息成功');
								} else {
									this.$message.success('添加锁具成功');
								}
								this.getTable()
								this.modelVisible = false;
								this.initFormData()
							} else {
								this.$message.warning(res.message);
							}
						})
					} else {
						return false;
					}
				});

			},
			handleCancel() {
				this.modelVisible = false;
				this.initFormData()
			},
			initFormData() {
				this.form = {
					lock_id: 0,
					type: 1,
					cnumber: '',
					number: '',
					snumber: '',
					auth_code: '',
				}
				this.formKey = Math.random()
			},
			lockChange(type, id) {
				let url = type == 0 ? '/api.device.lock.tolock/' : '/api.device.lock.nolock/'
				http({
					method: 'post',
					url: url,
					data: {
						lock_id: id,
					}
				}).then((res) => {
					if (res.code == "200") {
						this.$message.success(type == 0 ? '锁具锁定成功' : '锁具解锁成功');
						this.getTable()
					} else {
						this.$message.warning(res.message);
					}
				})
			},
		},
	};
</script>

<style lang="less" scoped>
	.table-pagination {
		text-align: right;
		padding: 20px 0px;
	}

	.filter-box {
		padding: 20px;
	}

	.btns10 {
		margin-left: 30px;

		button {
			margin-right: 10px;
		}
	}

	.table-box {
		padding: 0px 20px;
	}

	.ew-filter {
		display: flex;
		justify-content: space-between;
		padding: 0px 20px;
		padding-bottom: 20px;
		align-items: center;
	}
</style>
