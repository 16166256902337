<template>
	<div class="login">
		<div class="login-img1">
			<h3>你好！欢迎使用</h3>
			<span>现金缴存机业务综合管理系统</span>
			<p style="margin-top:0px">Cash Deposit Machine Business Management System</p>
		</div>
		<div class="login-form">
			<div class="right-box">
				<div class="tabs">
					<div class="active">用户登录</div>
				</div>
				<div class="inputs">
					<div>
						<img src="../assets/ddd1.png">
						<input type="text" v-model="admin_name" placeholder="请输入账号" />
					</div>
					
				</div>
				<div class="inputs">
					<div>
						<img src="../assets/ddd2.png">
						<input type="password" v-model="password" placeholder="请输入密码" />
					</div>
				</div>
				
				<div class="check">
					<div class="left">
						<a-checkbox v-model="checkpa"> <span style="color:#000000;font-size: 14px;">记住密码</span> </a-checkbox>
					</div>
				</div>
				<div class="sub" @click="home">登录</div>
				<div class="registered">

				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	import {
		mapActions
	} from "vuex";
	import http from '../http'
	export default {
		name: "login",
		data() {
			return {
				admin_name: localStorage.getItem('admin_name') || '',
				password: localStorage.getItem('password') || '',
				checkpa: false
			}
		},
		created() {
			let that = this;
			document.onkeydown = function(e) {
				e = window.event || e;
				if ((that.$route.path == '/login' && e.code == 'Enter' || e.code == 'enter')) { //验证在登录界面和按得键是回车键enter
					that.home()
				}
			}
			if (localStorage.getItem('password')) {
				this.checkpa = true
			}
		},
		methods: {
			registered() {
				this.$router.push({
					path: '/registered'
				})
			},
			home() {
				if (this.admin_name == '') {
					this.$message.warning('请输入账号')
					return
				}
				if (this.password == '') {
					this.$message.warning('请输入密码')
					return
				}
				http({
					method: 'post',
					url: '/api.admin.login/',
					data: {
						password: this.password,
						admin_name: this.admin_name
					}
				}).then((res) => {
					if (res.code == '200') {
						if (this.checkpa) {
							localStorage.setItem('admin_name', this.admin_name)
							localStorage.setItem('password', this.password)
						}
						// localStorage.setItem('username', res.data.admin_name)
						localStorage.setItem('corp_id_lo', res.data.corp_id || '')
						localStorage.setItem('node_id', res.data.node_id || '')
						localStorage.setItem('node_name', res.data.node_name || '')
						localStorage.setItem('token', res.data.token)
						localStorage.setItem('authority', JSON.stringify(res.data.authority))
						localStorage.setItem('avatar', res.data.avatar)
						localStorage.setItem('type_id', res.data.type_id)
						this.$message.success('登录成功');
						console.log(res.data.authority[0].list[0], 'res.data.authority[0][0]')
						let link = res.data.authority[0].list[0].router
						this.$router.push({
							path: link
						})
					} else {
						this.$message.error(res.message);
					}
				})

			},
			password() {
				this.$router.push({
					path: '/password'
				})
			},
			...mapActions({
				setTypes: "user/setTypes",
			}),
		}
	};
</script>

<style lang="less" scoped>
	@media screen and (max-width: 1500px) {
	   .login-img1 {
		   left: 8% !important;
	   }
	   .login-form {
	   		right: 12% !important;
	   }
	}
	.login {
		background-image: url(../assets/body.png);
		background-repeat:round;
		height: 100vh;
		overflow: hidden;
		.login-form {
			width: 380px;
			height: 495px;
			background: #FFFFFF;
			border-radius: 10px;
			position: absolute;
			right: 18%;
			top: 50%;
			transform: translate(0,-50%);

			.leftimg {
				width: 498px;
				height: 570px;
			}

			.right-box {
				width: 380px;
				position: relative;

				.tabs {
					justify-content: space-between;
					position: relative;
					height: 151px;
					padding-top: 76px;
					
					div {
						text-align: center;
						font-size: 30px;
						font-weight: 600;
						color: #3B63FF;
						line-height: 30px;
					}

					.icon {
						width: 32px;
						height: 5px;
						background: #3E88F7;
						border-radius: 3px;
						margin: 0 auto;
						display: block;
					}
				}

				.inputs {
					margin-bottom: 25px;
					width: 314px;
					height: 47px;
					background: #FFFFFF;
					border: 1px solid #DFDFDF;
					border-radius: 2px;
					margin: 0 auto; 
					margin-bottom: 25px;
					div {
						height: 47px;
						display: flex;
						align-items: center;

						img {
							width: 25px;
							height: 25px;
							margin-right: 13px;
							margin-left: 7px;
						}

						margin-bottom: 41px;

						input {
							width: 100%;
							height: 25px;
							font-size: 20px;
							font-weight: 400;
							color: #AAAAAA;
							line-height: 25px;
							border: none;
							outline: none;
							font-size: 16px;
							font-family: PingFang SC;
							font-weight: 400;
							color: #000000;
							line-height: 47px;
						}
					}

				}

				.check {
					margin-bottom: 37px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding-left: 37px;
					margin-top: 18px;

					.left {
						font-size: 16px;
						font-weight: 400;
						color: #9a9da4;
						line-height: 22px;
					}

					input {
						transform: scale(1.3);
					}

					.right {
						font-size: 16px;
						font-weight: 400;
						color: #9a9da4;
						line-height: 22px;
						cursor: pointer;
					}
				}

				.sub {
					width: 312px;
					height: 50px;
					background: #3758EE;
					box-shadow: 0px 5px 15px 0px rgba(0,118,252,0.2000);
					border-radius: 2px;
					margin: 0 auto;
					cursor: pointer;
					font-size: 18px;
					font-family: PingFang SC;
					font-weight: 600;
					color: #FFFFFF;
					line-height: 50px;
					text-align: center;

					&:hover {
						background-color: rgba(76, 132, 255, 0.71);
					}
				}

				.registered {
					position: absolute;
					bottom: 28px;
					left: 0px;
					width: 100%;
					text-align: center;
					cursor: pointer;

					&:hover {
						span {
							opacity: 0.8;
						}
					}

					span {
						font-size: 16px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #0a1f30;
						line-height: 22px;
						margin: 0 10px;
					}
				}
			}
		}
		.login-img1 {
			width: 650px;
			position: absolute;
			left: 25%;
			top: 50%;
			transform: translate(0,-50%);

			h3 {
				font-size: 55px;
				font-family: PingFang SC;
				font-weight: 500;
				color: #FFFFFF;
				margin-bottom: 27px;
				line-height: 55px;
			}

			span {
				font-size: 50px;
				font-family: PingFang SC;
				font-weight: 500;
				color: #FFFFFF;
				line-height: 36px;
				margin-bottom: 15px;
				display: block;
			}

			p {
				font-size: 22px;
				font-family: Avenir;
				font-weight: 500;
				color: #FFFFFF;
				line-height: 22px;
			}
		}

		input::-webkit-input-placeholder {
			/* WebKit browsers */
			color: #919191;
		}

		input:-moz-placeholder {
			/* Mozilla Firefox 4 to 18 */
			color: #919191;
		}

		input::-moz-placeholder {
			/* Mozilla Firefox 19+ */
			color: #919191;
		}

		input::-ms-input-placeholder {
			/* Internet Explorer 10+ */
			color: #919191;
		}
	}
</style>