import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from "@/components/Layout/Layout.vue";
import Main from "@/components/Layout/Main.vue";
import store from '@/store'
Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location){
	return originalPush.call(this, location).catch(err=>err)
}

const routes = [{
		path: '/',
		name: 'home',
		component: Layout,
		redirect: '/userinfo',
		children: [
			{
				path: '/userinfo',
				name: 'userinfo',
				title: '用户中心',
				meta: {
					title: '用户中心',
				},
				menuHide: true,
				component: () => import( /* webpackChunkName: "about" */ '../views/userinfo.vue')
			},
		]
	},
	{
		path: '/login',
		name: 'login',
		component: () => import( /* webpackChunkName: "about" */ '../views/login.vue')
	},

]

const router = new VueRouter({
	routes
})

var initRouter = false

function getRouterList(data) {
	function forData(list) {
		list = list.map((v) => {
			let d = {
				path: v.router,
				name: v.router_en || v.router_name,
				title: v.router_name,
				component: '',
				meta: {
					title: v.router_name,
					id: v.router_id
				},
				icon: v.icon,
				children: v.list || []
			}
			if (v.component == "Main") {
				d.component = Main
			} else {
				d.component = (resolve) => require([`@/views${v.component}`], resolve)
			}
			if (v.perms == 'hide') {
				d.hidden = true
			}
			if (v.list && v.list.length >= 1) {
				d.redirect = v.list[0].url
				let ar = forData(d.children || [])
				d.children = ar.length == 0 ? undefined : ar
				return d
			} else {
				return d
			}
		})
		return list
	}
	data = forData(data)
	let btns = []
	data.forEach((v2) => {
		if (v2.children) {
			v2.children.forEach((v3) => {
				v3.children.forEach((v4) => {
					btns.push(v4.meta.id)
				})
			})
		}
	})
	localStorage.setItem('btn_ids', JSON.stringify(btns))
	return data

	return initRouterList
}

router.beforeEach((to, from, next) => {
	if (to.name !== 'login' && !localStorage.getItem("token")) {
		next({
			name: 'login'
		})
	} else {
		if (localStorage.getItem('authority')) {
			let authority = JSON.parse(localStorage.getItem('authority'))
			if (!initRouter) {
				let routerData = getRouterList(authority)
				router.addRoutes([{
					path: '/deposit',
					name: 'deposit',
					title: '缴存项目',
					meta: {
						title: '缴存项目',
					},
					menuHide: true,
					component: Layout,
					children: routerData
				}])
				store.commit('permission/SET_CURRENT_ROUTES', routerData)
				initRouter = true
				next({...to})
			} else {
				next()
			}
		} else {
			next()
		}
	}
})

export default router
