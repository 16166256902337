<template>
	<a-layout id="components-layout-demo-custom-trigger">
		<a-layout-sider v-model="collapsed" :trigger="null" collapsible>
			<div class="logo" :class="{'wu': collapsed}">
				<span>现金缴存业务管理</span>
			</div>
			<a-menu theme="dark" mode="inline" :default-selected-keys="[sidebarActive]"
				:default-open-keys="[sidebarActiveOpen]">
				<a-sub-menu v-for="(data) in getRouterList" :key="data.path">
					<span slot="title">
						<a-icon :type="data.icon" /> <b class="titb">{{data.title}}</b>
					</span>
					<a-menu-item @click="linkPage(item.path)" v-for="(item) in data.children" :key="item.path">
						{{item.title}}
					</a-menu-item>
				</a-sub-menu>
			</a-menu>
		</a-layout-sider>
		<a-layout>
			<a-layout-header style="background: #fff; padding: 0" class="layout-header">
				<video ref="messageMp3" style="display: none;" >
				  <source src="../../assets/message.mp3" type="audio/mp3">
				</video>
				<a-icon class="trigger" style="color: #fff;" :type="collapsed ? 'menu-unfold' : 'menu-fold'"
					@click="() => (collapsed = !collapsed)" />
				<div class="header-right-btns">
					<a-dropdown>
						<div class="user-info">
							<div class="user-avater">
								<img v-if="!avatar" src="../../assets/img.png" />
								<img v-if="avatar" :src="avatar" />
							</div>
						</div>
						<a-menu slot="overlay">
							<a-menu-item>
								<a @click="updatePassword">个人资料</a>
							</a-menu-item>
							<a-menu-item>
								<a @click="updatePassword">安全中心</a>
							</a-menu-item>
							<a-menu-item>
								<a @click="updatePassword">绑定微信</a>
							</a-menu-item>
						</a-menu>
					</a-dropdown>
					<a-tooltip placement="bottom" v-if="messageList.length">
						<template slot="title">
							<div class="mes_list">
								<div class="mes_item" v-for="item in messageList">
									<div class="textdiv">
										<span v-if="item.level == 0" style="color: #87d068;">[普通]</span>
										<span v-else style="color: red;">[紧急]</span>
										<p>{{item.content}}</p>
									</div>
									<div class="timediv">{{item.add_time}}</div>
								</div>
								<div class="gdright">
									更多 <a-icon type="right" />
								</div>
							</div>
						</template>
						<div>
							<a-icon type="bell" />
							<span>消息</span>
						</div>
					</a-tooltip>
					<!-- <div v-else>
						<a-icon type="bell" />
						<span>消息</span>
					</div> -->
					<div @click="logut">
						<a-icon type="export" />
						<span>登出</span>
					</div>
				</div>
			</a-layout-header>

			<!-- <div class="header-tabs">
        <a-tabs
          v-model="activeKey"
          hide-add
          type="editable-card"
          @edit="onEdit"
        >
          <a-tab-pane
            v-for="pane in panes"
            :key="pane.key"
            :tab="pane.title"
            :closable="pane.closable"
          >
          </a-tab-pane>
        </a-tabs>
      </div> -->

			<a-layout-content :style="{
			  margin: '20px',
			  height: '100%',
			  overflow: 'auto'
			}">
				<div class="content-header-tag" v-if="sidebarActive !== '/deposit/home/home' && sidebarActive !== '/deposit/home/citydata'">
					<div v-for="(item, index) in headerTabs" :class="{'active': sidebarActive == item.path}"
						@click="tabLink(item)">
						<span>{{item.meta.title}}</span>
						<a-icon type="close" @click="tabRemove(item)" />
					</div>
				</div>
				<router-view />
			</a-layout-content>
		</a-layout>
		
		<a-modal
		      :title="messageModelData.title"
		      :visible="messageModelVisible"
			  :closable="false"
			  wrapClassName="messageModelData"
			  width="400px"
		    >
				<template slot="footer">
				        <a-button key="submit" type="primary" @click="messageModelVisible = false">
				          关闭
				        </a-button>
				      </template>
		      <p>{{ messageModelData.content }}</p>
		</a-modal>
	</a-layout>
</template>
<script>
	import http from '@/http'
	
	export default {
		data() {
			const panes = [{}];
			return {
				activeKey: panes[0].key,
				panes,
				newTabIndex: 0,
				collapsed: false,
				isHome: false,
				menus: [],

				headerTabs: [],
				avatar: '',
				
				messageList: [],
				
				messageModelVisible: false,
				messageModelData: {
					title: '标题',
					content: '内容'
				}
			};
		},
		created() {
			
		},
		mounted() {
			this.getMessageList()
			this.headerTabs.push(this.$route)
			if (localStorage.getItem('avatar')) {
				this.avatar = localStorage.getItem('avatar')
			}
			setInterval(() => {
				this.getNotice()
			}, 1000 * 60)
		},
		watch: {
			$route() {
				let isCz = false
				this.headerTabs.forEach((v) => {
					if (v.path == this.$route.path) {
						isCz = true
					}
				})
				if (!isCz) {
					this.headerTabs.push(this.$route)
				}
				if (this.headerTabs.length >= 10) {
					this.headerTabs = this.headerTabs.slice(this.headerTabs.length - 10)
				}
			}
		},
		computed: {
			// 侧边栏
			sidebarData() {
				return this.$router.options.routes[0].children[1].children;
			},
			sidebarActive() {
				const route = this.$route;
				const {
					meta,
					path
				} = route;
				return path
			},
			sidebarActiveOpen() {
				const route = this.$route;
				return route.matched[route.matched.length - 1].parent.path
			},
			getRouterList() {
				return this.$store.state.permission.currentRoutes
				// return this.$router.options.routes[0].children[1].children;
			}
		},
		methods: {
			onEdit() {},
			linkPage(url) {
				this.$router.push({
					path: url
				})
			},
			logut() {
				localStorage.removeItem('token')
				localStorage.removeItem('btn_ids')
				localStorage.removeItem('authority')
				this.$router.push({
					path: '/login'
				})
				location.reload();
			},
			updatePassword() {
				this.$router.push({
					path: '/userinfo'
				})
			},
			tabRemove(item) {
				this.headerTabs = this.headerTabs.filter((v) => {
					if (v.path == item.path) {
						return false
					}
					return true
				})
				if (this.headerTabs.length == 0) {
					this.$router.push({
						path: '/home'
					})
				} else {
					if (this.$route.path == item.path) {
						this.$router.push({
							path: this.headerTabs[this.headerTabs.length - 1].path
						})
					}
				}
			},
			tabLink(item) {
				this.$router.push({
					path: item.path
				})
			},
			getMessageList () {
				http({
					method: 'post',
					url: '/api.message.treelist/',
					data: {
				
					}
				}).then((res) => {
					this.messageList = res.data
				})
			},
			getNotice () {
				http({
					method: 'post',
					url: '/api.message.isnotice/',
					data: {
						
					}
				}).then((res) => {
					if (res.code == 200) {
						if (res.data.level == 0) {
							this.messageModelData = res.data
						} else {
							this.messageModelData = res.data
						}
						this.messageModelVisible = true
						this.getMessageList()
						this.$refs.messageMp3.play()
					}
				})
			}
		},
	};
</script>
<style lang="less">
	.messageModelData {
		.ant-modal-title{
			    text-align: left !important;
				    color: #000000;
				    font-weight: 700;
		}
		.ant-modal-footer{
			    text-align: right !important;
			    border-top: 1px solid #e8e8e8 !important;
			    padding-bottom: 10px !important;
		}
		.ant-modal-body {
			text-align: left;
		}
		.ant-modal {
			top: 300px;
		}
	}
	.notificss_error {
		.ant-notification-notice-message {
			color: red;
		}
	}
	.mes_list {
		background-color: #fff;
		min-width: 210px;
		// max-height: 600px;
		// overflow: auto;
		.mes_item {
			padding: 6px 10px;
			margin-bottom: 4px;
			border-bottom: 1px solid #ede3e3;
			.textdiv {
				    color: #333;
				    overflow: hidden;
					span {
						    float: left;
							margin-right: 4px;
					}
					p {
						margin-bottom: 0px;
					}
			}
			.timediv {
				padding-bottom: 10px;
				color: #999;
				display: flex;
				font-size: 12px;
				justify-content: flex-end;
			}
		}
	}
	.gdright {
		color: #666;
		display: flex;
		font-size: 12px;
		justify-content: flex-end;
		align-items: center;
		padding-bottom: 5px;
		padding-top: 5px;
		cursor: pointer;
	}
	.content-header-tag {
		display: flex;

		div {
			width: 100px;
			height: 40px;
			background: #FFFFFF;
			border-radius: 0px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-right: 1px solid #EEEEEE;
			cursor: pointer;

			&.active {
				background-color: #EEEEEE;
			}

			&:last-child {
				border-right: 0px;
			}

			span {
				font-size: 12px;
				font-weight: 400;
				color: #999999;
			}

			i {
				font-size: 12px;
				font-weight: 400;
				color: #999999;
				margin-left: 9px;
			}
		}
	}

	.header-right-btns {
		display: flex;
		padding-right: 39px;

		div {
			margin-left: 22px;
			cursor: pointer;

			&:hover {
				i {
					font-weight: 700;
				}

				span {
					font-weight: 700;
				}
			}
		}

		i {
			font-size: 16px;
			color: #fff;
			margin-right: 9px;
		}

		span {
			font-size: 14px;
			color: #fff;
		}
	}

	.ant-menu-item .anticon,
	.ant-menu-submenu-title .anticon {
		margin-right: 5px !important;
	}

	.ant-layout {
		background: #F8F8F8 !important;
	}

	.ant-layout-sider {
		background-color: #fff !important;

		.ant-menu-dark {
			background-color: #fff !important;
			color: rgba(0, 0, 0, 0.65) !important;

			.ant-menu-item {
				color: #000 !important;
			}
		}

		.ant-menu-dark .ant-menu-item-selected .anticon {
			color: #000 !important;
		}

		.ant-menu-submenu-title:hover {
			color: #000 !important;
		}

		.ant-menu-dark .ant-menu-submenu-open {
			color: #000 !important;
		}

		.ant-menu-dark .ant-menu-inline.ant-menu-sub {
			background-color: #fff !important;
			box-shadow: none;
		}

		.ant-menu.ant-menu-dark .ant-menu-item-selected {
			background-color: #e6f7ff !important;
		}

		.ant-menu-submenu-arrow {
			&:after {
				background: rgba(0, 0, 0, 0.65) !important;
			}

			&:before {
				background: rgba(0, 0, 0, 0.65) !important;
			}
		}

		.ant-menu-submenu-selected {
			color: #000 !important;

		}

	}

	.ant-menu-dark,
	.ant-menu-dark .ant-menu-sub {
		background-color: #fff !important;
	}

	.ant-menu-dark .ant-menu-item-selected {
		color: #000 !important;
		background-color: #e6f7ff !important;
	}

	.ant-menu-dark .ant-menu-item {
		color: rgba(0, 0, 0, 0.65) !important;
	}

	#components-layout-demo-custom-trigger .trigger {
		font-size: 18px;
		line-height: 64px;
		padding: 0 24px;
		cursor: pointer;
		transition: color 0.3s;
	}

	#components-layout-demo-custom-trigger .trigger:hover {
		color: #1890ff;
	}

	#components-layout-demo-custom-trigger .logo {
		height: 32px;
		// margin: 10px 16px !important;
		height: 64px;
	}

	#components-layout-demo-custom-trigger {
		.logo {
			font-weight: 700;
			display: flex;
			align-items: center;
			justify-content: center;
			background-image: url(../../assets/logo21.png);
			background-size: 25px 25px;
			background-repeat: no-repeat;
			background-position: 10px 20px;
			width: 200px;
			color: #000;
			font-size: 20px;
			background-color: #3473FF;

			span {
				position: relative;
				padding-left: 40px;
				color: #fff;

			}

			&.wu {
				background-position: 28px 20px;
				width: 80px;
				position: relative;

				span {
					display: none;
				}
			}
		}

		.header-tabs {
			margin: 24px 16px;
			margin-bottom: 0px;

			.ant-tabs-bar {
				margin-bottom: 0px;
			}
		}

		.layout-header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			background-color: #3473FF !important;

			.user-info {
				display: flex;
				align-items: center;
				cursor: pointer;

				.user-avater {
					width: 40px;
					height: 40px;
					background-color: #ccc;
					border-radius: 40px;
					overflow: hidden;

					img {
						display: block;
						width: 100%;
						height: 100%;
					}
				}
			}
		}
	}

	.ant-menu-dark.ant-menu-inline {
		overflow: auto;
		height: calc(100% - 64px);
	}

	/* 设置滚动条的样式 */
	.ant-menu-dark.ant-menu-inline::-webkit-scrollbar {
		width: 1px;
	}

	/* 滚动槽 */
	.ant-menu-dark.ant-menu-inline::-webkit-scrollbar-track {
		-webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.0);
		border-radius: 1px;
	}

	/* 滚动条滑块 */
	.ant-menu-dark.ant-menu-inline::-webkit-scrollbar-thumb {
		border-radius: 1px;
		background: rgba(0, 0, 0, 0.0);
		-webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
	}

	.ant-menu-dark.ant-menu-inline::-webkit-scrollbar-thumb:window-inactive {
		background: rgba(255, 0, 0, 0.0);
	}
</style>