<template>
	<div class="content-box-div">
		<div class="filter-box">
			<a-form-model layout="inline">
				<a-form-model-item label="客户选择">
					<!-- <a-cascader style="width: 300px;"
						:fieldNames="{label: 'corp_name', value: 'corp_id', children: 'list' }" :key="corp_id"
						:defaultValue="corp_id" :options="corpList" placeholder="请选择客户" @change="corpChange" /> -->
					<a-tree-select
					    v-model="corp_id"
						 @change="corpChange"
					   style="width: 300px;"
					    :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
					    :tree-data="corpList"
					    placeholder="请选择客户"
						:replaceFields="{children:'list', title:'corp_name', key:'corp_id', value: 'corp_id' }"
					  >
					  </a-tree-select>
				</a-form-model-item>
				<a-form-model-item label="网点选择">
					<a-select style="width: 300px;" v-model="node_id" placeholder="请选择网点" >
					   <a-select-option :value="item.node_id" v-for="(item, index) in nodeList" :key="index">
					       {{item.name}}
					   </a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item label="设备名称">
					<a-input size="default" v-model="name" placeholder="输入设备名称查询">
					</a-input>
				</a-form-model-item>
				<a-form-model-item label="设备编号">
					<a-input size="default" v-model="number" placeholder="输入设备编号查询">
					</a-input>
				</a-form-model-item>
				<a-form-model-item>
					<div class="btns10">
						<a-button type="primary" icon="search" @click="btnClick('查询')">
							查询
						</a-button>
						<a-button type="primary" icon="sync" @click="btnClick('重置')">
							重置
						</a-button>
						<a-button type="primary" icon="reload" @click="btnClick('刷新')">
							刷新
						</a-button>
					</div>
				</a-form-model-item>
			</a-form-model>
		</div>

		<div class="ew-filter">
			<div>
				已选金额：{{amount}}元 | 已选总条数：{{idlist.length}}
			</div>
			<div>
				<a-spin tip="处理中..." :spinning="spinning">
				<a-button v-if="btnIds.includes(128)" type="primary" icon="plus-circle" @click="toacc">
					日结
				</a-button>
				<a-button v-if="btnIds.includes(129)" type="primary" icon="ordered-list" @click="allacc" style="margin-left: 15px;">
					一键日结
				</a-button>
				</a-spin>
			</div>
		</div>

		<div class="table-box">
			<a-table :columns="columns" :data-source="tableData" :pagination="false" :row-selection="rowSelection"
				:expanded-row-keys.sync="expandedRowKeys" :rowKey='record=>record.decash_id'>
			</a-table>
			<div class="table-pagination">
				<a-pagination v-model="tablePage" @change="tabelPageChange" show-quick-jumper :total="tableTotal"
				:show-total="(total, range) => `共${total}条记录`"	:page-size="10" />
			</div>
		</div>

		<a-modal title="结算" :visible="modelVisible" @ok="handleOk" @cancel="handleCancel">
			<a-form-model :key="formKey" ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
				<a-form-model-item label="缴款金额" prop="amount">
					<a-input-number v-model="form.amount" placeholder="请输入缴款金额" style="width:100%" />
				</a-form-model-item>
				<a-form-model-item label="备注" >
					<a-textarea v-model="form.remark" placeholder="请输入备注" :auto-size="{ minRows: 3, maxRows: 5 }" />
				</a-form-model-item>
			</a-form-model>
		</a-modal>
	</div>
</template>

<script>
	import http from '@/http'


	const columns = [{
			title: '设备名称',
			dataIndex: 'name',
			key: 'name',
		}, {
			title: '设备编号',
			dataIndex: 'number',
			key: 'number',
		}, {
			title: '所属客户',
			dataIndex: 'corp_name',
			key: 'corp_name',
		}, {
			title: '网点名称',
			dataIndex: 'node_name',
			key: 'node_name',
		},
		{
			title: '用户名',
			dataIndex: 'user_name',
			key: 'user_name',
		},
		{
			title: '真实姓名',
			dataIndex: 'real_name',
			key: 'real_name',
		},
		{
			title: '存款类型',
			dataIndex: 'detype_name',
			key: 'detype_name',
		},
		{
			title: '纸币存款金额',
			dataIndex: 'cash_amount',
			key: 'cash_amount',
		},
		{
			title: '信封存款金额',
			dataIndex: 'envelope_amount',
			key: 'envelope_amount',
		},
		{
			title: '总金额',
			dataIndex: 'amount',
			key: 'amount',
		},
	];


	export default {
		data() {
			return {
				spinning:false,
				columns,
				corp_id: null,
				now_device:0,
				name: '',
				number: '',
				tableData: [],
				tableTotal: 0,
				tablePage: 1,
				corpList: [],
				corpTreeList: [],
				defaultCorp: [],
				expandedRowKeys: [],
				idlist:[],
				modelVisible: false,
				amount:'0.0',
				labelCol: {
					span: 6
				},
				wrapperCol: {
					span: 14
				},
				form: {
					decash_id: 0,
					corp_account: '',
					corp_bank: '',
					amount: '',
					remark: '',
				},
				rules: {
					corp_bank: [{
						required: true,
						message: '请输入结算银行',
						trigger: 'blur'
					}],
					corp_account: [{
						required: true,
						message: '请输入结算账户',
						trigger: 'blur'
					}],
					amount: [{
						required: true,
						message: '请输入缴款金额',
						trigger: 'blur'
					}],
					
				},
				formKey: Math.random(),
				btnIds: localStorage.getItem('btn_ids') ? JSON.parse(localStorage.getItem('btn_ids')) : [],
				
				nodeList: [],
				node_id: undefined
			};
		},
		computed: {
			rowSelection() {
				return {
					onChange: (selectedRowKeys, list) => {
						this.expandedRowKeys = selectedRowKeys
						this.idlist = selectedRowKeys
						this.getAmount()
					}
				}
			},
		},
		mounted() {
			http({
				method: 'post',
				url: '/api.corp.treelist/',
				data: {}
			}).then((res) => {
				this.corpList = res.data
				let corpTreeList = {}

				function forData(data, ids) {
					data.forEach((v) => {
						corpTreeList[v.corp_name + v.corp_id] = [...ids, v.corp_id]
						if (v.list.length) {
							forData(v.list, [...ids, v.corp_id])
						}
					})
				}
				res.data.forEach((v, i) => {
					corpTreeList[v.corp_name + v.corp_id] = v.corp_id
					if (v.list.length) {
						forData(v.list, [v.corp_id])
					}
					if (i == 0) {
						let defaultCorp = {
							...corpTreeList
						}
						let keys = Object.keys(defaultCorp)
						this.defaultCorp = v.corp_id
					}
				})
				this.corpTreeList = corpTreeList
				this.corp_id = this.defaultCorp
				this.getNodeList()
				this.getTable()
			})
		},
		methods: {
			getNodeList (corp_id) {
				http({
					method: 'post',
					url: '/api.corp.node.treelist/',
					data: {
						corp_id: this.corp_id
					}
				}).then((res) => {
					this.nodeList = res.data
				})
			},
			allacc(){
				this.$confirm({
					title: '确定一键日结所有交易记录？',
					okText: '确定',
					okType: 'primary',
					cancelText: '取消',
					onOk: () => {
						this.spinning = true
						this.todo()
					},
					onCancel: () => {

					},
				});
			},
			todo(){
				let that = this
				http({
					method: 'post',
					url: '/api.deposit.autoacc/',
					data: {}
				}).then((res) => {
					if (res.code == "200") {
						this.spinning = false
						this.$message.success('一键日结任务已提交');
						this.getTable()
						this.initFormData()
					} else {
						this.spinning = false
						this.$message.warning(res.message);
					}
				})
			},
			getAmount () {
				if(this.idlist.length==0){
					this.amount = '0.0'	
				}else{
					http({
						method: 'post',
						url: '/api.deposit.compute/',
						data: {decash_id:this.idlist.join(',')}
					}).then((res) => {
						if(res.code=='200'){
							this.amount = res.data.amount
							this.now_device = res.data.device_id
						}else{							
							this.$message.warning('请选择同一台设备');
							return
						}
					})
				}
			},
			toacc() {
				if (this.idlist.length == 0) {
					this.$message.warning('请先选择需要日结的数据');
					return
				}
				http({
					method: 'post',
					url: '/api.corp.getacc/',
					data: {device_id:this.now_device}
				}).then((res) => {
					if(res.code=='200'){
						this.form.amount = this.amount
						this.form.corp_account = res.data.account
						this.form.corp_bank = res.data.bank
						this.modelVisible = true
					}else{							
						this.$message.warning('选择的参数错误');
						return
					}
				})
			},
			corpChange(data) {
				this.corp_id = data
				this.node_id = undefined
				this.getNodeList()
			},
			chcorpChange(data) {
				this.form.corp_id = data[data.length - 1]
			},
			tabelPageChange(page) {
				this.tablePage = page
				this.getTable()
			},
			btnClick(type) {
				if (type == '查询') {
					this.getTable()
					this.idlist =[]
					this.now_device = 0
					this.amount = '0.0'
				} else if (type == '重置') {
					this.corp_id = this.defaultCorp
					this.name = ''
					this.number = ''
					this.node_id = undefined
					this.tablePage = 1
					this.getTable()
					this.idlist =[]
					this.now_device = 0
					this.amount = '0.0'
					this.getNodeList()
				} else if (type == '刷新') {
					this.getTable()
				}
			},
			getTable() {
				http({
					method: 'post',
					url: '/api.deposit.notoacc/',
					data: {
						page: this.tablePage,
						name: this.name,
						number: this.number,
						corp_id: this.corp_id,
						node_id: this.node_id ? this.node_id : 0
					}
				}).then((res) => {
					this.tableData = res.data.list
					this.tableTotal = res.data.total_count
				})
			},
			handleOk() {
				this.$refs.ruleForm.validate(valid => {
					if (valid) {
						let newData = {
							...this.form,
							decash_id: this.idlist.join(',')
						}
						http({
							method: 'post',
							url: '/api.deposit.toacc/',
							data: newData
						}).then((res) => {
							this.spinning = true
							if (res.code == "200") {
								this.spinning = false
								this.$message.success('日结成功');
								this.getTable()
								this.modelVisible = false;
								this.initFormData()
							} else {
								this.spinning = false
								this.$message.warning(res.message);
							}
						})
					} else {
						return false;
					}
				});
			
			},
			handleCancel() {
				this.modelVisible = false;
				this.initFormData()
			},
			initFormData() {
				this.form = {
					decash_id: 0,
					corp_account: '',
					corp_bank: '',
					amount: '',
					remark: '',
				}
				this.idlist = []
				this.amount = '0.0'
				this.now_device = 0
				this.formKey = Math.random()
			},
		},
	};
</script>

<style lang="less" scoped>
	.table-pagination {
		text-align: right;
		padding: 20px 0px;
	}

	.filter-box {
		padding: 20px;
	}

	.btns10 {
		margin-left: 30px;

		button {
			margin-right: 10px;
		}
	}

	.table-box {
		padding: 0px 20px;
	}

	.ew-filter {
		display: flex;
		justify-content: space-between;
		padding: 0px 20px;
		padding-bottom: 20px;
		align-items: center;
	}
</style>
