<template>
	<div class="content-box-div">
		<div class="filter-box">
			<a-form-model layout="inline">
				<a-form-model-item label="客户选择">
					<!-- <a-cascader style="width: 300px;"
						:fieldNames="{label: 'corp_name', value: 'corp_id', children: 'list' }" :key="corp_id"
						:defaultValue="corp_id" :options="corpList" placeholder="请选择客户" @change="corpChange" /> -->
					<a-tree-select
					    v-model="corp_id"
						 @change="corpChange"
					   style="width: 300px;"
					    :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
					    :tree-data="corpList"
					    placeholder="请选择客户"
						:replaceFields="{children:'list', title:'corp_name', key:'corp_id', value: 'corp_id' }"
					  >
					  </a-tree-select>
				</a-form-model-item>
				<a-form-model-item label="设备名称">
					<a-input size="default" v-model="name" placeholder="输入设备名称查询">
					</a-input>
				</a-form-model-item>
				<a-form-model-item label="设备编号">
					<a-input size="default" v-model="number" placeholder="输入设备编号查询">
					</a-input>
				</a-form-model-item>
				<a-form-model-item>
					<div class="btns10">
						<a-button type="primary" icon="search" @click="btnClick('查询')">
							查询
						</a-button>
						<a-button type="primary" icon="sync" @click="btnClick('重置')">
							重置
						</a-button>
						<a-button type="primary" icon="reload" @click="btnClick('刷新')">
							刷新
						</a-button>
					</div>
				</a-form-model-item>
			</a-form-model>
		</div>

		<div class="ew-filter">
			<div class="ew-filter-div">
				<div>
					<div>汇总在机金额</div>
					<div>{{online_amount_total}}</div>
				</div>
			</div>
			<a-button v-if="btnIds.includes(122)" type="primary" icon="plus-circle" @click="dcClick">
				导出
			</a-button>
		</div>

		<div class="table-box">
			<a-table :columns="columns" :data-source="tableData" :pagination="false">
				<a slot="name" slot-scope="text">{{ text }}</a>
				<div slot="age5">
					<span class="btn-span">正常</span>
				</div>
				<div slot="age6" slot-scope="tags">
					<span class="btn-span">
						编辑
					</span>
				</div>
			</a-table>
			<div class="table-pagination">
				<a-pagination v-model="tablePage" @change="tabelPageChange" show-quick-jumper :total="tableTotal" 
				:show-total="(total, range) => `共${total}条记录`"	:page-size="10" />
			</div>
		</div>


	</div>
</template>

<script>
	import http from '@/http'
	
	const columns = [{
			title: '设备名称',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: '网点名称',
			dataIndex: 'node_name',
			key: 'node_name',
		},
		{
			title: '新钞袋ID',
			key: 'cashbag_sn',
			dataIndex: 'cashbag_sn',
		},
		{
			title: '存款次数',
			key: 'num',
			dataIndex: 'num',
		},
		{
			title: '纸币金额',
			key: 'cash_amount',
			dataIndex: 'cash_amount',
		},
		{
			title: '纸币数量',
			key: 'ccount',
			dataIndex: 'ccount',
		},
		{
			title: '信封金额',
			key: 'envelope_amount',
			dataIndex: 'envelope_amount',
		},
		{
			title: '异常金额',
			key: 'un_amount',
			dataIndex: 'un_amount',
		},
		{
			title: '总金额',
			key: 'amount',
			dataIndex: 'amount',
		},
		{
			title: '更新时间',
			key: 'online_time',
			dataIndex: 'online_time',
		},
	];

	export default {
		data() {
			return {
				columns,
				online_amount_total:0,
				corp_id: null,
				name: '',
				number: '',
				tableData: [],
				tableTotal: 0,
				tablePage: 1,
				corpList: [],
				corpTreeList: [],
				defaultCorp: [],
				
				btnIds: localStorage.getItem('btn_ids') ? JSON.parse(localStorage.getItem('btn_ids')) : []
			};
		},
		mounted() {
			http({
				method: 'post',
				url: '/api.corp.treelist/',
				data: {}
			}).then((res) => {
				this.corpList = res.data
				let corpTreeList = {}
				function forData(data, ids) {
					data.forEach((v) => {
						corpTreeList[v.corp_name + v.corp_id] = [...ids, v.corp_id]
						if (v.list.length) {
							forData(v.list, [...ids, v.corp_id])
						}
					})
				}
				res.data.forEach((v, i) => {
					corpTreeList[v.corp_name + v.corp_id] = v.corp_id
					if (v.list.length) {
						forData(v.list, [v.corp_id])
					}
					if (i == 0) {
						let defaultCorp = {...corpTreeList}
						let keys = Object.keys(defaultCorp)
						this.defaultCorp = v.corp_id
					}
				})
				this.corpTreeList = corpTreeList
				this.corp_id = this.defaultCorp
				this.getTable()
			})
		},
		methods: {
			tabelPageChange (page) {
				this.tablePage = page
				this.getTable()
			},
			handleSubmit(e) {
			},
			corpChange(data) {
				this.corp_id = data
			},
			chcorpChange(data) {
				this.form.corp_id = data[data.length - 1]
			},
			btnClick(type) {
				if (type == '查询') {
					this.getTable()
				} else if (type == '重置') {
					this.corp_id = this.defaultCorp
					this.name = ''
					this.number = '' 
					this.tablePage = 1
					this.getTable()
				} else if (type == '刷新') {
					this.getTable()
				}
			},
			getTable() {
				http({
					method: 'post',
					url: '/api.deposit.online/',
					data: {
						page: this.tablePage,
						name: this.name,
						number: this.number,
						corp_id: this.corp_id
					}
				}).then((res) => {
					this.tableData = res.data.list
					this.tableTotal = res.data.total_count
					this.online_amount_total = res.data.online_amount_total
				})
			},
			dcClick () {
				http({
					method: 'post',
					url: '/api.account.online.export/',
					data: {
						name: this.name,
						number: this.number,
						corp_id: this.corp_id
					}
				}).then((res) => {
					if (res.code == '200') {
						window.open(res.data)
					} else {
						if (res.code != '201') {
							this.$message.error(res.message);
						}
					}
				})
			}
		},
	};
</script>

<style lang="less" scoped>
	.table-pagination {
		text-align: right;
		padding: 20px 0px;
	}

	.filter-box {
		padding: 20px;
	}

	.btns10 {
		margin-left: 30px;

		button {
			margin-right: 10px;
		}
	}

	.table-box {
		padding: 0px 20px;
	}

	.ew-filter {
		display: flex;
		justify-content: space-between;
		padding: 0px 20px;
		padding-bottom: 20px;
		align-items: center;
	}
	

	.ew-filter-div {
		display: flex;
		align-items: center;
		justify-items: center;
		flex:1;

		div {
			display: flex;
			flex:1;
			flex-direction: column;
			align-items: center;
		}
	}
</style>
