<template>
	<div class="content-box-div">
		<div class="filter-box">
			<a-form-model layout="inline" :key="filterKey">
				<a-form-model-item label="客户选择">
					<!-- <a-cascader style="width: 300px;"
						:fieldNames="{label: 'corp_name', value: 'corp_id', children: 'list' }" :key="corp_id"
						:defaultValue="corp_id" :options="corpList" placeholder="请选择客户" @change="corpChange" /> -->
					<a-tree-select v-model="corp_id" @change="corpChange" style="width: 300px;"
						:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" :tree-data="corpList"
						placeholder="请选择客户"
						:replaceFields="{children:'list', title:'corp_name', key:'corp_id', value: 'corp_id' }">
					</a-tree-select>
				</a-form-model-item>
				<a-form-model-item label="账号">
					<a-input size="default" v-model="admin_name" placeholder="输入账号查询">
					</a-input>
				</a-form-model-item>
				<a-form-model-item label="姓名">
					<a-input size="default" v-model="real_name" placeholder="输入姓名查询">
					</a-input>
				</a-form-model-item>
				<a-form-model-item label="审核时间">
					<a-range-picker @change="onChange" :disabledDate="disabledDate" format="YYYY-MM-DD HH:mm:ss" show-time>
						<a-icon slot="suffixIcon" type="smile" />
					</a-range-picker>
				</a-form-model-item>
				<a-form-model-item>
					<div class="btns10">
						<a-button type="primary" icon="search" @click="btnClick('查询')">
							查询
						</a-button>
						<a-button type="primary" icon="sync" @click="btnClick('重置')">
							重置
						</a-button>
						<a-button type="primary" icon="reload" @click="btnClick('刷新')">
							刷新
						</a-button>
					</div>
				</a-form-model-item>
			</a-form-model>
		</div>
		
		<div class="ew-filter">
			<div></div>
			<a-button type="primary" icon="plus-circle" v-if="btnIds.includes(156)" @click="dcClick">
				导出
			</a-button>
		</div>

		<div class="table-box audittable">
			<a-table :columns="columns" :data-source="tableData" :pagination="false"
				:rowKey='record=>record.decash_id'>
				<a slot="name" slot-scope="text">{{ text }}</a>
				<div slot="age5">
					<span class="btn-span">正常</span>
				</div>
				<div slot="age6" slot-scope="text, record">
					<span class="btn-span" @click="detail(record.da_id)">
						详情
					</span>
				</div>
			</a-table>
			<div class="table-pagination">
				<a-pagination v-model="tablePage" @change="tabelPageChange" show-quick-jumper :total="tableTotal"
					:show-total="(total, range) => `共${total}条记录`" :page-size="10" />
			</div>
		</div>


		<a-modal :closable="false" wrapClassName="auditmodel" title="审核打印单据" :visible="auditVisible" width="1200px"
			@ok="handleAuditOk" :footer="null">
			<div>
				<div id="printArea">
					<h1 style="text-align: center;line-height: 50px;padding-top: 30px;">存款确认单</h1>
					<div style="display: flex;justify-content: space-between;margin-bottom: 5px;position: relative;">
						<div></div>
						<div style="position: absolute;width: 100%;text-align: center;top: 0px;left: 0px;">{{getDateTime('date')}}</div>
						<div><span class="fhbox">【</span>{{dyDetail.node_name}}<span class="fhbox">】</span></div>
					</div>
					<a-table :rowKey='record=>record.decash_id' bordered :columns="columnsAudit"
						:data-source="auditList" :pagination="false">
						<a slot="name" slot-scope="text">{{ text }}</a>
						<div slot="index" slot-scope="text, record, index">
							{{(index + 1) == auditList.length ? '合计' : (index + 1)}}
						</div>
						<div style="text-align: left;" slot="node_name" slot-scope="text">{{ text }}</div>
						<div style="text-align: right;" slot="envelope_amount" slot-scope="text">{{ text }}</div>
						<div style="text-align: right;" slot="cash_amount" slot-scope="text">{{ text }}</div>
						<div style="text-align: right;" slot="cash_count" slot-scope="text">{{ text }}</div>
						<div style="text-align: right;" slot="amount" slot-scope="text">{{ text }}</div>
						<div style="text-align: left;" slot="real_name" slot-scope="text">{{ text }}</div>
						<div style="text-align: left;" slot="remark" slot-scope="text">{{ text }}</div>
					</a-table>
					<div style="display: flex;justify-content: space-between;margin-top: 5px;">
						<div>网点复核员: {{dyDetail.real_name}}</div>
						<div></div>
						<div>打印时间: {{dyDateTime}}</div>
					</div>
				</div>
				<div class="modefooter">
					
					<div v-print="print">
						<a-button size="large" @click="prBtn" type="primary">
							打 印
						</a-button>
					</div>
					<a-button size="large" @click="auditVisible = false">
						关 闭
					</a-button>
				</div>
			</div>
		</a-modal>
	</div>
</template>

<script>
	import http from '@/http'
	import moment from 'moment'
	const columns = [{
			title: '网点名称',
			dataIndex: 'node_name',
			key: 'node_name',
		}, {
			title: '审核日期',
			dataIndex: 'check_date',
			key: 'check_date',
		},
		{
			title: '纸币张数',
			dataIndex: 'ccount',
			key: 'ccount',
		},
		{
			title: '纸币金额(元)',
			key: 'camount',
			dataIndex: 'camount',
		},
		{
			title: '封包金额(元)',
			key: 'eamount',
			dataIndex: 'eamount',
		},
		{
			title: '金额小计(元)',
			key: 'amount',
			dataIndex: 'amount',
		},
		{
			title: '账号',
			key: 'admin_name',
			dataIndex: 'admin_name',
		},
		{
			title: '姓名',
			key: 'real_name',
			dataIndex: 'real_name',
		},
		{
			title: '审核时间',
			key: 'check_time',
			dataIndex: 'check_time',
		},
		{
			title: '操作',
			key: 'age6',
			scopedSlots: {
				customRender: 'age6'
			},
		},
	];


	export default {
		data() {
			return {
				columns,
				columnsAudit: [{
						title: '序号',
						key: 'index',
						width: 50,
						align: 'center',
						scopedSlots: {
							customRender: 'index'
						},
					},
					{
						title: '网点',
						key: 'node_name',
						dataIndex: 'node_name',
						align: 'center',
						scopedSlots: {
							customRender: 'node_name'
						},
						customRender: (text, row, index) => {
							if (this.auditList.length == (index + 1)) {
								return {
									attrs: {
										colSpan: 2,
									},
								};
							} else {
								return {
									children: <div style="text-align: left;">{text}</div>,
									attrs: {
										colSpan: 1,
									},
								};
							}
						},
					},
					{
						title: '存款时间',
						key: 'add_time',
						dataIndex: 'add_time',
						width: 130,
						align: 'center',
						customRender: (text, row, index) => {
							if (this.auditList.length == (index + 1)) {
								return {
									attrs: {
										colSpan: 0,
									},
								};
							} else {
								return {
									children: <span>{text}</span>,
									attrs: {
										colSpan: 1,
									},
								};
							}
						},
					},
					{
						title: '纸币张数',
						key: 'cash_count',
						dataIndex: 'cash_count', 
						width: 70,
						align: 'center',
						scopedSlots: {
							customRender: 'cash_count'
						},
					},
					{
						title: '纸币金额',
						key: 'camount',
						dataIndex: 'camount',
						width: 100,
						align: 'center',
						scopedSlots: {
							customRender: 'camount'
						},
					},
					{
						title: '封包金额',
						key: 'eamount',
						dataIndex: 'eamount',
						width: 80,
						align: 'center',
						scopedSlots: {
							customRender: 'eamount'
						},
					},
					{
						title: '金额小计',
						key: 'amount',
						dataIndex: 'amount',
						width: 100,
						align: 'center',
						scopedSlots: {
							customRender: 'amount'
						},
					},
					{
						title: '存款员',
						key: 'real_name',
						dataIndex: 'real_name',
						width: 110,
						align: 'center',
						scopedSlots: {
							customRender: 'real_name'
						},
						customRender: (text, row, index) => {
							if (this.auditList.length == (index + 1)) {
								return {
									attrs: {
										colSpan: 4,
									},
								};
							} else {
								return {
									children: <div style="text-align: left;">{text}</div>,
									attrs: {
										colSpan: 1,
									},
								};
							}
						},
					},
					{
						title: '存款类型',
						key: 'detype_name',
						dataIndex: 'detype_name',
						width: 80,
						align: 'center',
						scopedSlots: {
							customRender: 'detype_name'
						},
						customRender: (text, row, index) => {
							if (this.auditList.length == (index + 1)) {
								return {
									attrs: {
										colSpan: 0,
									},
								};
							} else {
								return {
									children: <div style="text-align: left;">{text}</div>,
									attrs: {
										colSpan: 1,
									},
								};
							}
						},
					},
					{
						title: '备注',
						key: 'remark',
						dataIndex: 'remark',
						width: 100,
						align: 'center',
						scopedSlots: {
							customRender: 'remark'
						},
						customRender: (text, row, index) => {
							if (this.auditList.length == (index + 1)) {
								return {
									attrs: {
										colSpan: 0,
									},
								};
							} else {
								return {
									children: <div style="text-align: left;">{text}</div>,
									attrs: {
										colSpan: 1,
									},
								}
							}
						},
					},
					{
						title: '确认时间',
						key: 'check_time',
						dataIndex: 'check_time',
						align: 'center',
						width: 130,
						customRender: (text, row, index) => {
							if (this.auditList.length == (index + 1)) {
								return {
									attrs: {
										colSpan: 0,
									},
								};
							} else {
								return {
									children: <div style="text-align: center;">{text}</div>,
									attrs: {
										colSpan: 1,
									},
								};
							}
						},
					},
				],
				corp_id: null,
				admin_name: '',
				real_name: '',
				start_date: '',
				end_date: '',
				
				tableData: [],
				tableTotal: 0,
				tablePage: 1,
				corpList: [],
				corpTreeList: [],
				defaultCorp: [],
				expandedRowKeys: [],
				idlist: [],

				auditVisible: false,
				isPrBtn: false,
				print: {
					id: 'printArea',
					popTitle: '', // 打印配置页上方标题
					extraHead: '', //最上方的头部文字，附加在head标签上的额外标签,使用逗号分隔
					preview: '', // 是否启动预览模式，默认是false（开启预览模式，可以先预览后打印）
					previewTitle: '', // 打印预览的标题（开启预览模式后出现）,
					previewPrintBtnLabel: '', // 打印预览的标题的下方按钮文本，点击可进入打印（开启预览模式后出现）
					zIndex: '', // 预览的窗口的z-index，默认是 20002（此值要高一些，这涉及到预览模式是否显示在最上面）   
					previewBeforeOpenCallback() {
						
					}, //预览窗口打开之前的callback（开启预览模式调用）
					previewOpenCallback() {
						console.log(1)
					}, // 预览窗口打开之后的callback（开启预览模式调用）
					beforeOpenCallback() {
						console.log(2)
					}, // 开启打印前的回调事件
					openCallback() {
						console.log(3)
					}, // 调用打印之后的回调事件
					closeCallback(that) {
					
					}, //关闭打印的回调事件（无法确定点击的是确认还是取消）
					url: '',
					standard: '',
					extraCss: '',
				},

				selectedRows: [],
				selectedRowKeys: [],

				auditList: [],
				userInfo: {},
				dyDateTime: '',
				
				node_name: localStorage.getItem('node_name') ? localStorage.getItem('node_name') : '',
				isDy: false,
				
				dyDetail: {},
				filterKey: Math.random(),
				
				btnIds: localStorage.getItem('btn_ids') ? JSON.parse(localStorage.getItem('btn_ids')) : [],
			};
		},
		computed: {

		},
		mounted() {
			this.getUserInfo()
			http({
				method: 'post',
				url: '/api.corp.treelist/',
				data: {}
			}).then((res) => {
				this.corpList = res.data
				let corpTreeList = {}

				function forData(data, ids) {
					data.forEach((v) => {
						corpTreeList[v.corp_name + v.corp_id] = [...ids, v.corp_id]
						if (v.list.length) {
							forData(v.list, [...ids, v.corp_id])
						}
					})
				}
				res.data.forEach((v, i) => {
					corpTreeList[v.corp_name + v.corp_id] = v.corp_id
					if (v.list.length) {
						forData(v.list, [v.corp_id])
					}
					if (i == 0) {
						let defaultCorp = {
							...corpTreeList
						}
						let keys = Object.keys(defaultCorp)
						this.defaultCorp = v.corp_id
					}
				})
				this.corpTreeList = corpTreeList
				this.corp_id = this.defaultCorp
				this.getTable()
			})
		},
		methods: {
			dcClick () {
				http({
					method: 'post',
					url: '/api.deposit.audit.export/',
					data: {
						corp_id: this.corp_id,
						admin_name: this.admin_name,
						real_name: this.real_name,
						start_date: this.start_date,
						end_date: this.end_date,
					}
				}).then((res) => {
					if (res.code == '200') {
						window.open(res.data)
					} else {
						if (res.code != '201') {
							this.$message.error(res.message);
						}
					}
				})
			},
			disabledDate (current) {
			  return current && current > moment().endOf('day')
			},
			detail(id) {
				http({
					method: 'post',
					url: '/api.deposit.auditdetail/',
					data: {
						da_id: id
					}
				}).then((res) => {
					let cash_count = 0
					let cash_amount = 0
					let envelope_amount = 0
					let amount = 0
					this.dyDetail = res.data
					res.data.list.forEach((v) => {
						cash_count += parseFloat(v.cash_count)
						cash_amount += parseFloat(v.camount)
						envelope_amount += parseFloat(v.eamount)
						amount += parseFloat(v.amount)
					})
					this.auditList = [...res.data.list, {
						cash_count: cash_count,
						camount: cash_amount.toFixed(2),
						eamount: envelope_amount.toFixed(2),
						amount: amount.toFixed(2),
					}]
					this.dyDateTime = this.getDateTime()
					this.auditList = this.auditList.map((v) => {
						return {
							...v,
							qrtime: this.getDateTime()
						}
					})
					console.log(this.auditList, 'this.auditListthis.auditList')
					this.auditVisible = true
				})
			},
			onChange(data) {
				console.log(data, 'datadatadata')
				this.start_date = moment(data[0]).format('YYYY-MM-DD HH:mm:ss')
				this.end_date = moment(data[1]).format('YYYY-MM-DD HH:mm:ss')
			},
			getUserInfo () {
				http({
					method: 'post',
					url: '/api.admin.mydetail/',
					data: {
				
					}
				}).then((res) => {
					this.userInfo = res.data
				})
			},
			getDateTime (type) {
				var today = new Date();
				
				//日期
				var DD = String(today.getDate()).padStart(2, '0'); // 获取日
				var MM = String(today.getMonth() + 1).padStart(2, '0'); //获取月份，1 月为 0
				var yyyy = today.getFullYear(); // 获取年
				
				// 时间
				let hh =  String(today.getHours()).padStart(2, '0');       //获取当前小时数(0-23)
				let mm = String(today.getMinutes()).padStart(2, '0');     //获取当前分钟数(0-59)
				let ss = String(today.getSeconds()).padStart(2, '0');     //获取当前秒数(0-59)
				today = yyyy + '-' + MM + '-' + DD + ' ' + hh + ':' + mm + ':' + ss;
				if (type == 'date') {
					return yyyy + '-' + MM + '-' + DD
				} else {
					return today
				}
			},
			onSelect(record, selected, selectedRows, nativeEvent) {
				console.log(record, selected, selectedRows, nativeEvent, 'record, selected, selectedRows, nativeEvent')
				if (selected) {
					this.selectedRows.push(record)
					this.selectedRowKeys.push(record.decash_id)
				} else {
					const index = this.selectedRows.findIndex(item => item.decash_id === record.decash_id)
					console.log(index, record, 'indexindexindex')
					this.selectedRows.splice(index, 1)
					this.selectedRowKeys.splice(index, 1)
				}
				console.log(this.selectedRows, 'selectedRowsselectedRows')
			},
			prBtn () {
				this.dyDateTime = this.getDateTime()
			},
			updateTime () {
				this.isDy = true
				this.dyDateTime = this.getDateTime()
				console.log(this.auditList, 'this.auditListthis.auditList')
				this.auditList = this.auditList.map((v) => {
					return {
						...v,
						qrtime: this.dyDateTime
					}
				})
				let ids = []
				this.auditList.forEach((v) => {
					if (v.decash_id) {
						ids.push({
							decash_id: v.decash_id,
							remark: v.remark
						})
					}
				})
				http({
					method: 'post',
					url: '/api.deposit.toaudit/',
					data: {
						list: JSON.stringify(ids),
						check_time: this.dyDateTime
					}
				}).then((res) => {
					if (res.code == 200) {
						this.selectedRows = []
						this.selectedRowKeys = []
						this.getTable()
					}
				})
			},
			auditBtn() {
				if (this.selectedRows.length == 0) {
					this.$message.error('请先勾选审核数据');
					return
				}
				this.isPrBtn = false
				let cash_count = 0
				let cash_amount = 0
				let envelope_amount = 0
				let amount = 0
				this.selectedRows.forEach((v) => {
					cash_count += parseFloat(v.cash_count)
					cash_amount += parseFloat(v.cash_amount)
					envelope_amount += parseFloat(v.envelope_amount)
					amount += parseFloat(v.amount)
				})
				this.auditList = [...this.selectedRows, {
					cash_count: cash_count,
					cash_amount: cash_amount.toFixed(2),
					envelope_amount: envelope_amount.toFixed(2),
					amount: amount.toFixed(2),
				}]
				this.dyDateTime = this.getDateTime()
				this.auditList = this.auditList.map((v) => {
					return {
						...v,
						qrtime: this.getDateTime()
					}
				})
				this.auditVisible = true
			},
			handleAuditOk() {
				
			},
			tabelPageChange(page) {
				this.tablePage = page
				this.getTable()
			},
			handleSubmit(e) {},
			corpChange(data) {
				this.corp_id = data
			},
			chcorpChange(data) {
				this.form.corp_id = data[data.length - 1]
			},
			btnClick(type) {
				if (type == '查询') {
					this.getTable()
				} else if (type == '重置') {
					this.corp_id = this.defaultCorp
					this.admin_name = ''
					this.real_name = ''
					this.start_date = ''
					this.end_date = ''
					this.tablePage = 1
					this.filterKey = Math.random()
					this.getTable()
				} else if (type == '刷新') {
					this.getTable()
				}
			},
			getTable() {
				console.log(this.start_date, 'this.start_date')
				http({
					method: 'post',
					url: '/api.deposit.auditlist/',
					data: {
						page: this.tablePage,
						corp_id: this.corp_id,
						admin_name: this.admin_name,
						real_name: this.real_name,
						start_date: this.start_date,
						end_date: this.end_date,
					}
				}).then((res) => {
					this.tableData = res.data.list
					this.tableTotal = res.data.total_count
				})
			}
		},
	};
</script>

<style lang="less" scoped>
	/deep/ .audittable {
  	thead {
  		tr {
  			th:nth-child(1) {
  				.ant-table-selection {
  					display: none;
  				}
  			}
  		}
  	}
  }
	.modefooter {
		display: flex;
		justify-content: center;
		margin-top: 40px;

		button:nth-child(1) {
			margin-right: 20px;
		}
	}

	.table-pagination {
		text-align: right;
		padding: 20px 0px;
	}

	.filter-box {
		padding: 20px;
	}

	.btns10 {
		margin-left: 30px;

		button {
			margin-right: 10px;
		}
	}

	.table-box {
		padding: 0px 20px;
	}

	.ew-filter {
		display: flex;
		justify-content: space-between;
		padding: 0px 20px;
		padding-bottom: 20px;
		align-items: center;
	}
</style>