<template>
	<div class="content-box-div">
		<div class="filter-box">
			<a-form-model layout="inline">
				<a-form-model-item label="客户选择">
					<!-- <a-cascader style="width: 300px;"
						:fieldNames="{label: 'corp_name', value: 'corp_id', children: 'list' }" :key="corp_id"
						:defaultValue="corp_id" :options="corpList" placeholder="请选择客户" @change="corpChange" /> -->
					<a-tree-select v-model="corp_id" @change="corpChange" style="width: 300px;"
						:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" :tree-data="corpList"
						placeholder="请选择客户"
						:replaceFields="{children:'list', title:'corp_name', key:'corp_id', value: 'corp_id' }">
					</a-tree-select>
				</a-form-model-item>
				<a-form-model-item>
					<div class="btns10">
						<a-button type="primary" icon="search" @click="btnClick('查询')">
							查询
						</a-button>
						<a-button type="primary" icon="sync" @click="btnClick('重置')">
							重置
						</a-button>
						<a-button type="primary" icon="reload" @click="btnClick('刷新')">
							刷新
						</a-button>
					</div>
				</a-form-model-item>
			</a-form-model>
		</div>

		<div class="ew-filter">
			<div>
				已选总条数：{{selectedRows.length}}
			</div>
			<div>
				<a-button v-if="btnIds.includes(126)" type="primary" icon="plus-circle" @click="auditBtn">
					审核
				</a-button>
			</div>
		</div>

		<div class="table-box audittable">
			<a-table :key="tableKey" :columns="columns" :data-source="tableData" :pagination="false"
				:row-selection="{ onSelect: onSelect, onSelectAll: onSelectAll, getCheckboxProps: record => ({ disabled: true }), }" :expanded-row-keys.sync="expandedRowKeys"
				:rowKey='record=>record.decash_id'>
				<a slot="name" slot-scope="text">{{ text }}</a>
				<div slot="age5">
					<span class="btn-span">正常</span>
				</div>
				<div slot="age6" slot-scope="tags">
					<span class="btn-span">
						编辑
					</span>
				</div>
			</a-table>
			<div class="table-pagination">
				<a-pagination v-model="tablePage" @change="tabelPageChange" show-quick-jumper :total="tableTotal"
					:show-total="(total, range) => `共${total}条记录`" :page-size="10" />
			</div>
		</div>


		<a-modal :closable="false" wrapClassName="auditmodel" title="审核打印单据" :visible="auditVisible" width="1200px"
			@ok="handleAuditOk" :footer="null">
			<div>
				<div id="printArea">
					<h1 style="text-align: center;line-height: 50px;padding-top: 30px;">存款确认单</h1>
					<div style="display: flex;justify-content: space-between;margin-bottom: 5px;position: relative;">
						<div></div>
						<div style="position: absolute;width: 100%;text-align: center;top: 0px;left: 0px;">{{getDateTime('date')}}</div>
						<div><span class="fhbox">【</span>{{node_name}}<span class="fhbox">】</span></div>
					</div>
					<a-table :rowKey='record=>record.decash_id' bordered :columns="columnsAudit"
						:data-source="auditList" :pagination="false">
						<a slot="name" slot-scope="text">{{ text }}</a>
						<div slot="index" slot-scope="text, record, index">
							{{(index + 1) == auditList.length ? '合计' : (index + 1)}}
						</div>
						<div style="text-align: left;" slot="node_name" slot-scope="text">{{ text }}</div>
						<div style="text-align: right;" slot="envelope_amount" slot-scope="text">{{ text }}</div>
						<div style="text-align: right;" slot="cash_amount" slot-scope="text">{{ text }}</div>
						<div style="text-align: right;" slot="cash_count" slot-scope="text">{{ text }}</div>
						<div style="text-align: right;" slot="amount" slot-scope="text">{{ text }}</div>
						<div style="text-align: left;" slot="real_name" slot-scope="text">{{ text }}</div>
						<div style="text-align: left;" slot="remark" slot-scope="text">{{ text }}</div>
					</a-table>
					<div style="display: flex;justify-content: space-between;margin-top: 5px;">
						<div>网点复核员: {{userInfo.real_name}}</div>
						<div></div>
						<div>打印时间: {{dyDateTime}}</div>
					</div>
				</div>
				<div class="modefooter">
					<a-button size="large" @click="auditVisible = false">
						取 消
					</a-button>
					<div v-print="print">
						<a-button size="large" v-if="isPrBtn" @click="prBtn" type="primary">
							重新打印
						</a-button>
						<a-button size="large" v-else @click="updateTime" type="primary">
							审核并打印
						</a-button>
					</div>
				</div>
			</div>
		</a-modal>
	</div>
</template>

<script>
	import http from '@/http'

	const columns = [{
			title: 'ID',
			dataIndex: 'decash_id',
			key: 'decash_id',
		}, {
			title: '设备名称',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: '设备编号',
			dataIndex: 'number',
			key: 'number',
		},
		{
			title: '所属客户',
			key: 'corp_name',
			dataIndex: 'corp_name',
		},
		{
			title: '网点名称',
			key: 'node_name',
			dataIndex: 'node_name',
		},
		{
			title: '用户名',
			key: 'user_name',
			dataIndex: 'user_name',
		},
		{
			title: '真实姓名',
			key: 'real_name',
			dataIndex: 'real_name',
		},
		{
			title: '存款类型',
			key: 'detype_name',
			dataIndex: 'detype_name',
		},
		{
			title: '纸币存款金额',
			key: 'cash_amount',
			dataIndex: 'cash_amount',
		},
		{
			title: '纸币张数',
			key: 'cash_count',
			dataIndex: 'cash_count',
		},
		{
			title: '信封存款金额',
			key: 'envelope_amount',
			dataIndex: 'envelope_amount',
		},
		{
			title: '总金额',
			key: 'amount',
			dataIndex: 'amount',
		},
		{
			title: '流水号',
			key: 'trade_no',
			dataIndex: 'trade_no',
		}
	];


	export default {
		data() {
			return {
				columns,
				columnsAudit: [{
						title: '序号',
						key: 'index',
						width: 50,
						align: 'center',
						scopedSlots: {
							customRender: 'index'
						},
					},
					{
						title: '网点',
						key: 'node_name',
						dataIndex: 'node_name',
						align: 'center',
						scopedSlots: {
							customRender: 'node_name'
						},
						customRender: (text, row, index) => {
							if (this.auditList.length == (index + 1)) {
								return {
									attrs: {
										colSpan: 2,
									},
								};
							} else {
								return {
									children: <div style="text-align: left;">{text}</div>,
									attrs: {
										colSpan: 1,
									},
								};
							}
						},
					},
					{
						title: '存款时间',
						key: 'add_time',
						dataIndex: 'add_time',
						width: 130,
						align: 'center',
						customRender: (text, row, index) => {
							if (this.auditList.length == (index + 1)) {
								return {
									attrs: {
										colSpan: 0,
									},
								};
							} else {
								return {
									children: <span>{text}</span>,
									attrs: {
										colSpan: 1,
									},
								};
							}
						},
					},
					{
						title: '纸币张数',
						key: 'cash_count',
						dataIndex: 'cash_count', 
						width: 70,
						align: 'center',
						scopedSlots: {
							customRender: 'cash_count'
						},
					},
					{
						title: '纸币金额',
						key: 'cash_amount',
						dataIndex: 'cash_amount',
						width: 100,
						align: 'center',
						scopedSlots: {
							customRender: 'cash_amount'
						},
					},
					{
						title: '封包金额',
						key: 'envelope_amount',
						dataIndex: 'envelope_amount',
						width: 80,
						align: 'center',
						scopedSlots: {
							customRender: 'envelope_amount'
						},
					},
					{
						title: '金额小计',
						key: 'amount',
						dataIndex: 'amount',
						width: 100,
						align: 'center',
						scopedSlots: {
							customRender: 'amount'
						},
					},
					{
						title: '存款员',
						key: 'real_name',
						dataIndex: 'real_name',
						width: 110,
						align: 'center',
						scopedSlots: {
							customRender: 'real_name'
						},
						customRender: (text, row, index) => {
							if (this.auditList.length == (index + 1)) {
								return {
									attrs: {
										colSpan: 4,
									},
								};
							} else {
								return {
									children: <div style="text-align: left;">{text}</div>,
									attrs: {
										colSpan: 1,
									},
								};
							}
						},
					},
					{
						title: '存款类型',
						key: 'detype_name',
						dataIndex: 'detype_name',
						width: 80,
						align: 'center',
						scopedSlots: {
							customRender: 'detype_name'
						},
						customRender: (text, row, index) => {
							if (this.auditList.length == (index + 1)) {
								return {
									attrs: {
										colSpan: 0,
									},
								};
							} else {
								return {
									children: <div style="text-align: left;">{text}</div>,
									attrs: {
										colSpan: 1,
									},
								};
							}
						},
					},
					{
						title: '备注',
						key: 'remark',
						dataIndex: 'remark',
						width: 100,
						align: 'center',
						scopedSlots: {
							customRender: 'remark'
						},
						customRender: (text, row, index) => {
							if (this.auditList.length == (index + 1)) {
								return {
									attrs: {
										colSpan: 0,
									},
								};
							} else {
								if (this.isDy) {
									return {
										children: <div style="text-align: left;">{text}</div>,
										attrs: {
											colSpan: 1,
										},
									}
								} else {
									const h = this.$createElement
									const obj = h('input', { 'style': { 'width': '120px', 'border': 'none' }, domProps: {
										value: text // 假设有一个data属性inputValue
									  }, 'attrs': { 'value': this.test }, 'on': {
									  input: function(event) {
									    this.auditList[index].remark = event.target.value
									  }.bind(this)
									}
									}, '')
									return {
										attrs: {
											colSpan: 1,
										},
										children: obj
									}
								}
							}
						},
					},
					{
						title: '确认时间',
						key: 'qrtime',
						dataIndex: 'qrtime',
						align: 'center',
						width: 130,
						customRender: (text, row, index) => {
							if (this.auditList.length == (index + 1)) {
								return {
									attrs: {
										colSpan: 0,
									},
								};
							} else {
								return {
									children: <div style="text-align: center;">{text}</div>,
									attrs: {
										colSpan: 1,
									},
								};
							}
						},
					},
				],
				corp_id: null,
				name: '',
				number: '',
				tableData: [],
				tableTotal: 0,
				tablePage: 1,
				corpList: [],
				corpTreeList: [],
				defaultCorp: [],
				expandedRowKeys: [],
				idlist: [],

				auditVisible: false,
				isPrBtn: false,
				print: {
					id: 'printArea',
					popTitle: '', // 打印配置页上方标题
					extraHead: '', //最上方的头部文字，附加在head标签上的额外标签,使用逗号分隔
					preview: '', // 是否启动预览模式，默认是false（开启预览模式，可以先预览后打印）
					previewTitle: '', // 打印预览的标题（开启预览模式后出现）,
					previewPrintBtnLabel: '', // 打印预览的标题的下方按钮文本，点击可进入打印（开启预览模式后出现）
					zIndex: '', // 预览的窗口的z-index，默认是 20002（此值要高一些，这涉及到预览模式是否显示在最上面）   
					previewBeforeOpenCallback() {
						
					}, //预览窗口打开之前的callback（开启预览模式调用）
					previewOpenCallback() {
						console.log(1)
					}, // 预览窗口打开之后的callback（开启预览模式调用）
					beforeOpenCallback() {
						console.log(2)
					}, // 开启打印前的回调事件
					openCallback() {
						console.log(3)
					}, // 调用打印之后的回调事件
					closeCallback(that) {
						that.$confirm({
						        title: '请确认已经打印出单据？',
								okText: '打印成功',
								cancelText: '重新打印',
						        onOk() {
						          that.auditVisible = false
						        },
						        onCancel() {
									that.isPrBtn = true
						          console.log('Cancel');
						        },
						        class: 'test',
						});
					}, //关闭打印的回调事件（无法确定点击的是确认还是取消）
					url: '',
					standard: '',
					extraCss: '',
				},

				selectedRows: [],
				selectedRowKeys: [],

				auditList: [],
				userInfo: {},
				dyDateTime: '',
				
				node_name: localStorage.getItem('node_name') ? localStorage.getItem('node_name') : '',
				isDy: false,
				
				btnIds: localStorage.getItem('btn_ids') ? JSON.parse(localStorage.getItem('btn_ids')) : [],
				
				typeList: [],
				
				tableKey: Math.random()
			};
		},
		computed: {

		},
		mounted() {
			this.getUserInfo()
			http({
				method: 'post',
				url: '/api.corp.treelist/',
				data: {}
			}).then((res) => {
				this.corpList = res.data
				let corpTreeList = {}

				function forData(data, ids) {
					data.forEach((v) => {
						corpTreeList[v.corp_name + v.corp_id] = [...ids, v.corp_id]
						if (v.list.length) {
							forData(v.list, [...ids, v.corp_id])
						}
					})
				}
				res.data.forEach((v, i) => {
					corpTreeList[v.corp_name + v.corp_id] = v.corp_id
					if (v.list.length) {
						forData(v.list, [v.corp_id])
					}
					if (i == 0) {
						let defaultCorp = {
							...corpTreeList
						}
						let keys = Object.keys(defaultCorp)
						this.defaultCorp = v.corp_id
					}
				})
				this.corpTreeList = corpTreeList
				this.corp_id = this.defaultCorp
				this.getTable()
			})
		},
		methods: {
			getUserInfo () {
				http({
					method: 'post',
					url: '/api.admin.mydetail/',
					data: {
				
					}
				}).then((res) => {
					this.userInfo = res.data
				})
			},
			getDateTime (type) {
				var today = new Date();
				
				//日期
				var DD = String(today.getDate()).padStart(2, '0'); // 获取日
				var MM = String(today.getMonth() + 1).padStart(2, '0'); //获取月份，1 月为 0
				var yyyy = today.getFullYear(); // 获取年
				
				// 时间
				let hh =  String(today.getHours()).padStart(2, '0');       //获取当前小时数(0-23)
				let mm = String(today.getMinutes()).padStart(2, '0');     //获取当前分钟数(0-59)
				let ss = String(today.getSeconds()).padStart(2, '0');     //获取当前秒数(0-59)
				today = yyyy + '-' + MM + '-' + DD + ' ' + hh + ':' + mm + ':' + ss;
				if (type == 'date') {
					return yyyy + '-' + MM + '-' + DD
				} else {
					return today
				}
			},
			onSelectAll (selected, check, alllist, cc, dd) {
				if (selected) {
					let list = []
					let ids = []
					alllist.forEach((v) => {
						list.push(v)
						ids.push(v.decash_id)
					})
					this.selectedRows.forEach((v) => {
						if (ids.includes(v.decash_id)) {
							
						} else {
							list.push(v)
							ids.push(v.decash_id)
						}
					})
					this.selectedRows = list
					this.selectedRowKeys = ids
				} else {
					
					let list = []
					let ids = alllist.map((v) => {
						return v.decash_id
					})
					console.log(ids, 'idsids')
					this.selectedRows.forEach((v) => {
						if (ids.includes(v.decash_id)) {
							
						} else {
							list.push(v)
						}
					})
					this.selectedRows = list
					this.selectedRowKeys = list.map((v) => {
						return v.decash_id
					})
				}
				console.log(selected, this.selectedRows, this.selectedRowKeys, 888)
			},
			onSelect(record, selected, selectedRows, nativeEvent) {
				console.log(record, selected, selectedRows, nativeEvent, 'record, selected, selectedRows, nativeEvent')
				if (selected) {
					this.selectedRows.push(record)
					this.selectedRowKeys.push(record.decash_id)
				} else {
					const index = this.selectedRows.findIndex(item => item.decash_id === record.decash_id)
					console.log(index, record, 'indexindexindex')
					this.selectedRows.splice(index, 1)
					this.selectedRowKeys.splice(index, 1)
				}
				console.log(this.selectedRows, 'selectedRowsselectedRows')
			},
			prBtn () {
				this.isDy = true
				this.dyDateTime = this.getDateTime()
			},
			updateTime () {
				this.isDy = true
				this.dyDateTime = this.getDateTime()
				console.log(this.auditList, 'this.auditListthis.auditList')
				this.auditList = this.auditList.map((v) => {
					return {
						...v,
						qrtime: this.dyDateTime
					}
				})
				let ids = []
				this.auditList.forEach((v) => {
					if (v.decash_id) {
						ids.push({
							decash_id: v.decash_id,
							remark: v.remark
						})
					}
				})
				http({
					method: 'post',
					url: '/api.deposit.toaudit/',
					data: {
						list: JSON.stringify(ids),
						check_time: this.dyDateTime
					}
				}).then((res) => {
					if (res.code == 200) {
						this.selectedRows = []
						this.selectedRowKeys = []
						this.getTable()
					}
				})
			},
			auditBtn() {
				if (this.selectedRows.length == 0) {
					this.$message.error('请先勾选审核数据');
					return
				}
				this.isPrBtn = false
				let cash_count = 0
				let cash_amount = 0
				let envelope_amount = 0
				let amount = 0
				this.selectedRows.forEach((v) => {
					cash_count += parseFloat(v.cash_count)
					cash_amount += parseFloat(v.cash_amount)
					envelope_amount += parseFloat(v.envelope_amount)
					amount += parseFloat(v.amount)
				})
				this.auditList = [...this.selectedRows, {
					cash_count: cash_count,
					cash_amount: cash_amount.toFixed(2),
					envelope_amount: envelope_amount.toFixed(2),
					amount: amount.toFixed(2),
				}]
				this.dyDateTime = this.getDateTime()
				this.auditList = this.auditList.map((v) => {
					return {
						...v,
						qrtime: this.getDateTime()
					}
				})
				this.auditVisible = true
			},
			handleAuditOk() {
				
			},
			tabelPageChange(page) {
				this.tablePage = page
				this.getTable()
			},
			handleSubmit(e) {},
			corpChange(data) {
				this.corp_id = data
			},
			chcorpChange(data) {
				this.form.corp_id = data[data.length - 1]
			},
			btnClick(type) {
				if (type == '查询') {
					this.getTable()
				} else if (type == '重置') {
					this.corp_id = this.defaultCorp
					this.name = ''
					this.number = ''
					this.tablePage = 1
					this.getTable()
				} else if (type == '刷新') {
					this.getTable()
				}
				this.selectedRows = []
				this.selectedRowKeys = []
				this.tableKey = Math.random()
			},
			getTable() {
				http({
					method: 'post',
					url: '/api.deposit.dayauditlist/',
					data: {
						page: this.tablePage,
						corp_id: this.corp_id
					}
				}).then((res) => {
					this.tableData = res.data.list
					this.tableTotal = res.data.total_count
				})
			},
			dcClick() {
				http({
					method: 'post',
					url: '/api.account.online.export/',
					data: {
						name: this.name,
						number: this.number,
						corp_id: this.corp_id
					}
				}).then((res) => {
					if (res.code == '200') {
						window.open(res.data)
					} else {
						if (res.code != '201') {
							this.$message.error(res.message);
						}
					}
				})
			}
		},
	};
</script>

<style lang="less" scoped>
		
	.modefooter {
		display: flex;
		justify-content: center;
		margin-top: 40px;

		button:nth-child(1) {
			margin-right: 20px;
		}
	}

	.table-pagination {
		text-align: right;
		padding: 20px 0px;
	}

	.filter-box {
		padding: 20px;
	}

	.btns10 {
		margin-left: 30px;

		button {
			margin-right: 10px;
		}
	}

	.table-box {
		padding: 0px 20px;
	}

	.ew-filter {
		display: flex;
		justify-content: space-between;
		padding: 0px 20px;
		padding-bottom: 20px;
		align-items: center;
	}
</style>