<template>
	<div class="content-box-div">
		<div class="filter-box">
			<a-form-model layout="inline">
				<a-form-model-item label="类型名称">
					<a-input size="default" v-model="type_name" placeholder="输入类型名称查询">
					</a-input>
				</a-form-model-item>
				<a-form-model-item>
					<div class="btns10">
						<a-button type="primary" icon="search" @click="btnClick('查询')">
							查询
						</a-button>
						<a-button type="primary" icon="sync" @click="btnClick('重置')">
							重置
						</a-button>
						<a-button type="primary" icon="reload" @click="btnClick('刷新')">
							刷新
						</a-button>
					</div>
				</a-form-model-item>
			</a-form-model>
		</div>

		<div class="ew-filter">
			<div></div>
			<a-button type="primary" v-if="btnIds.includes(144)" @click="modelVisible = true" icon="plus-circle">
				添加
			</a-button>
		</div>

		<div class="table-box">
			<a-table :columns="columns" :data-source="tableData" :pagination="false">
				<a slot="name" slot-scope="text">{{ text }}</a>
				<div slot="age1" slot-scope="text, record">
					<a-tag color="blue" v-if="record.is_update == 1">
						启用
					</a-tag>
					<a-tag color="orange" v-if="record.is_update == 0">
						禁止
					</a-tag>
				</div>
				<div slot="age2" slot-scope="text, record">
					<span class="btn-span" v-if="btnIds.includes(145)" @click="edit(record)">
						编辑
					</span>
					<a-popconfirm placement="top" v-if="btnIds.includes(146)" ok-text="确定" cancel-text="取消" @confirm="remove(record.type_id)">
						<template slot="title">
							<p>是否确定删除该设备类型？</p>
						</template>
						<span class="btn-span btn-span-delete">
							删除
						</span>
					</a-popconfirm>
				</div>
			</a-table>
			<div class="table-pagination">
				<a-pagination v-model="tablePage" @change="tabelPageChange" show-quick-jumper :total="tableTotal"
				:show-total="(total, range) => `共${total}条记录`" :page-size="10" />
			</div>
		</div>

		<a-modal :title="form.type_id ? '编辑' : '添加'" :visible="modelVisible" @ok="handleOk" @cancel="handleCancel">
			<a-form-model :key="formKey" ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol"
				:wrapper-col="wrapperCol">

				<a-form-model-item label="类型名称" prop="type_name">
					<a-input v-model="form.type_name" placeholder="请输入类型名称" />
				</a-form-model-item>

				<a-form-model-item label="清分机型号" prop="sorter_model">
					<a-input v-model="form.sorter_model" placeholder="请输入清分机型号" />
				</a-form-model-item>

				<a-form-model-item label="备注">
					<a-input v-model="form.remark" placeholder="请输入备注" />
				</a-form-model-item>	

				<a-form-model-item label="软件简称" prop="vname">
					<a-input v-model="form.vname" placeholder="请输入软件简称" />
				</a-form-model-item>
								
				<a-form-model-item label="是否开启升级" prop="is_update">
					<a-switch un-checked-children="否" checked-children="是" :checked="form.is_update==1?true:false" @change="changeUpdate"/>
				</a-form-model-item>

				<a-form-model-item label="最新版本号" prop="version">
					<a-input v-model="form.version" placeholder="请输入软件最新版本号" />
				</a-form-model-item>	

				<a-form-model-item label="升级提示">
					<a-textarea v-model="form.content" placeholder="请输入安装包升级提示" :auto-size="{ minRows: 3, maxRows: 5 }" />
				</a-form-model-item>			

				<a-form-model-item label="上传安装包" >
					<a-spin tip="上传文件中..." :spinning="spinning">
						<a-upload class="ddetws" accept=".apk" name="file" :default-file-list="defaultFileList" :customRequest="customRequest"	>
							<a-button>
								<a-icon type="upload" /> 上传安装包
							</a-button>
						</a-upload>
						<p style="line-height: 20px;">{{form.url}}</p>
					</a-spin>
				</a-form-model-item>
				
				<a-form-model-item label="AppKey" prop="jg_appid">
					<a-input v-model="form.jg_appid" placeholder="请输入极光推送AppKey(仅限安卓)" />
				</a-form-model-item>
				
				<a-form-model-item label="Master Secret" prop="jg_secret">
					<a-input v-model="form.jg_secret" placeholder="请输入极光推送Master Secret(仅限安卓)" />
				</a-form-model-item>

			</a-form-model>
		</a-modal>
	</div>
</template>

<script>
	import http from '@/http'
	const columns = [{
			title: '类型名称',
			dataIndex: 'type_name',
			key: 'type_name',
		},	
		{
			title: '清分机型号',
			dataIndex: 'sorter_model',
			key: 'sorter_model',
		},
		{
			title: '备注',
			dataIndex: 'remark',
			key: 'remark',
		},	
		{
			title: '软件简称',
			dataIndex: 'vname',
			key: 'v_name',
		},		
		{
			title: '最新版本号',
			dataIndex: 'version',
			key: 'version',
		},
		{
			title: '软件升级提示',
			dataIndex: 'content',
			key: 'content',
		},
		{
			title: '是否开启',
			key: 'age1',
			scopedSlots: {
				customRender: 'age1'
			},
		},
		{
			title: '操作',
			key: 'age2',
			scopedSlots: {
				customRender: 'age2'
			},
		},
	];
	export default {
		data() {
			return {
				modelVisible: false,
				columns,
				labelCol: {
					span: 6
				},
				wrapperCol: {
					span: 14
				},
				other: '',
				form: {
					type_id: 0,
					vname: '',
					type_name: '',
					sorter_model:'',
					remark: '',
					content: '',
					version: '',
					url: '',
					jg_appid: '',
					jg_secret: '',
					is_update:0,
				},
				rules: {
					type_name: [{
						required: true,
						message: '请输入类型名称',
						trigger: 'blur'
					}],
					sorter_model: [{
						required: true,
						message: '请输入清分机型号',
						trigger: 'blur'
					}],
					vname: [{
						required: true,
						message: '请输入软件简称',
						trigger: 'blur'
					}],
					jg_appid: [{
						required: true,
						message: '请输入AppKey',
						trigger: 'blur'
					}],
					jg_secret: [{
						required: true,
						message: '请输入Master Secret',
						trigger: 'blur'
					}],
					version: [{
						required: true,
						message: '请输入软件最新版本号',
						trigger: 'blur'
					}],
					url: [{
						required: true,
						message: '请上传版本安装包',
						trigger: 'blur'
					}],
				},

				type_name: '',
				tableData: [],
				tableTotal: 0,
				tablePage: 1,
				formKey: Math.random(),
				
				defaultFileList: [],
				spinning: false,
				
				btnIds: localStorage.getItem('btn_ids') ? JSON.parse(localStorage.getItem('btn_ids')) : []
			};
		},
		mounted() {
			this.getTable()
		},
		methods: {
			handleSubmit(e) {
			},
			customRequest(data) {
				this.spinning = true
				let formData = new FormData()
				// 向formData实例中追加属性 (参数1：属性名 参数2：属性值)
				formData.append('file', data.file)
				http({
					method: 'post',
					url: '/api.config.upload.file/',
					ContentType: {
						'Content-Type': 'multipart/form-data'
					},
					data: {
						isFile: true,
						file: data.file
					}
				}).then((res) => {
					if (res.code == 200) {
						this.form.url = res.data
					} else {
						this.form.url = ''
						this.$message.warning('安装包上传失败');
					}
					this.spinning = false
				})
			},
			btnClick(type) {
				if (type == '查询') {
					this.getTable()
				} else if (type == '重置') {
					this.type_name = ''
					this.tablePage = 1
					this.getTable()
				} else if (type == '刷新') {
					this.getTable()
				}
			},
			getTable() {
				http({
					method: 'post',
					url: '/api.device.type.list/',
					data: {
						type_name: this.type_name,
						page: this.tablePage
					}
				}).then((res) => {
					this.tableData = res.data.list
					this.tableTotal = res.data.total_count
				})
			},
			tabelPageChange(page) {
				this.tablePage = page
				this.getTable()
			},
			changeUpdate(e){
				if(e){
					this.$nextTick(()=>{
						this.form.is_update = 1
					})
				}else{
					this.$nextTick(()=>{
						this.form.is_update = 0
					})
				}
			},
			remove(id) {
				http({
					method: 'post',
					url: '/api.device.type.del/',
					data: {
						type_id: id,
					}
				}).then((res) => {
					if (res.code == "200") {
						this.$message.success('删除设备类型成功');
						this.getTable()
					} else {
						this.$message.warning(res.message);
					}
				})
			},
			edit(data) {
				this.form = {
					...data
				}
				this.modelVisible = true;
			},

			handleOk() {
				if (!this.form.url) {
					this.$message.warning('请先上传文件');
					return
				}
				this.$refs.ruleForm.validate(valid => {
					if (valid) {
						let newData = {
							...this.form
						}
						http({
							method: 'post',
							url: '/api.device.type.add/',
							data: newData
						}).then((res) => {
							if (res.code == "200") {
								if (this.form.type_id) {
									this.$message.success('编辑设备类型信息成功');
								} else {
									this.$message.success('添加设备类型成功');
								}
								this.getTable()
								this.modelVisible = false;
								this.initFormData()
							} else {
								this.$message.warning(res.message);
							}
						})
					} else {
						return false;
					}
				});

			},
			handleCancel() {
				this.modelVisible = false;
				this.initFormData()
			},
			initFormData() {
				this.form = {
					type_id: 0,
					vname: '',
					type_name: '',
					sorter_model:'',
					remark: '',
					version: '',
					content: '',
					url: '',
					jg_appid: '',
					jg_secret: '',
					is_update:0,
				}
				this.formKey = Math.random()
			},
		},
	};
</script>

<style lang="less" scoped>
	.table-pagination {
		text-align: right;
		padding: 20px 0px;
	}

	.filter-box {
		padding: 20px;
	}

	.btns10 {
		margin-left: 30px;

		button {
			margin-right: 10px;
		}
	}

	.table-box {
		padding: 0px 20px;
	}

	.ew-filter {
		display: flex;
		justify-content: space-between;
		padding: 0px 20px;
		padding-bottom: 20px;
		align-items: center;
	}
</style>
