<template>
	<div class="content-box-div">
		<div class="filter-box" :key="filterKey">
			<a-form-model layout="inline">
				<a-form-model-item label="客户选择">
					<a-tree-select
					    v-model="corp_id"
						 @change="corpChange"
					   style="width: 300px;"
					    :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
					    :tree-data="corpList"
					    placeholder="请选择客户"
						:replaceFields="{children:'list', title:'corp_name', key:'corp_id', value: 'corp_id' }"
					  >
					  </a-tree-select>
				</a-form-model-item>
				<a-form-model-item label="用户名">
					<a-input size="default" v-model="auditor_name" placeholder="输入用户名查询">
					</a-input>
				</a-form-model-item>
				<a-form-model-item label="日期查询">
					<a-range-picker @change="onChange" :disabledDate="disabledDate" format="YYYY-MM-DD" show-time>
						<a-icon slot="suffixIcon" type="smile" />
					</a-range-picker>
				</a-form-model-item>
			</a-form-model>
			<a-form-model layout="inline">
				<a-form-model-item>
					<div class="btns10" style="margin-left: 0px;margin-top: 10px;">
						<a-button type="primary" icon="search" @click="btnClick('查询')">
							查询
						</a-button>
						<a-button type="primary" icon="sync" @click="btnClick('重置')">
							重置
						</a-button>
						<a-button type="primary" icon="reload" @click="btnClick('刷新')">
							刷新
						</a-button>
					</div>
				</a-form-model-item>
			</a-form-model>
		</div>

		<div class="ew-filter">
			<div></div>
			<a-button v-if="btnIds.includes(97)" type="primary" icon="plus-circle" @click="dcClick">
				导出
			</a-button>
		</div>

		<div class="table-box">
			<a-table :columns="columns" :data-source="tableData" :pagination="false">
			</a-table>
			<div class="table-pagination">
				<a-pagination v-model="tablePage" @change="tabelPageChange" show-quick-jumper :total="tableTotal" 
				:show-total="(total, range) => `共${total}条记录`"	:page-size="10" />
			</div>
		</div>


	</div>
</template>

<script>
	import http from '@/http'
	import moment from 'moment'
	const columns = [
        {
			title: '角色名称',
			dataIndex: 'arole_name',
			key: 'arole_name',
		},{
			title: '用户名',
			dataIndex: 'auditor_name',
			key: 'auditor_name',
		},{
			title: '姓名',
			dataIndex: 'real_name',
			key: 'real_name',
		},
		{
			title: '存款日期',
			key: 'audit_date',
			dataIndex: 'audit_date',
		},
		{
			title: '存款审核金额',
			key: 'amount',
			dataIndex: 'amount',
		},
		{
			title: '油品审核金额',
			key: 'oamount',
			dataIndex: 'oamount',
		},
		{
			title: '非油品审核金额',
			key: 'tamount',
			dataIndex: 'tamount',
		},
		{
			title: '存款汇总金额',
			key: 'total_amount',
			dataIndex: 'total_amount',
		},
		{
			title: '油品汇总金额',
			key: 'total_oamount',
			dataIndex: 'total_oamount',
		},
		{
			title: '非油品汇总金额',
			key: 'total_tamount',
			dataIndex: 'total_tamount',
		},
		{
			title: '审核时间',
			key: 'update_time',
			dataIndex: 'update_time',
		},
		{
			title: '状态',
			key: 'status_txt',
			dataIndex: 'status_txt',
		},
	];
	export default {
		data() {
			return {
				columns,
				corp_id: null,
				auditor_name: '',
				start_date: '',
				end_date: '',
				tableData: [],
				tableTotal: 0,
				tablePage: 1,
				corpList: [],
				corpTreeList: [],
				defaultCorp: [],
				
				filterKey: Math.random(),
				
				btnIds: localStorage.getItem('btn_ids') ? JSON.parse(localStorage.getItem('btn_ids')) : []
			};
		},
		mounted() {
			http({
				method: 'post',
				url: '/api.corp.treelist/',
				data: {}
			}).then((res) => {
				this.corpList = res.data
				let corpTreeList = {}
				function forData(data, ids) {
					data.forEach((v) => {
						corpTreeList[v.corp_name + v.corp_id] = [...ids, v.corp_id]
						if (v.list.length) {
							forData(v.list, [...ids, v.corp_id])
						}
					})
				}
				res.data.forEach((v, i) => {
					corpTreeList[v.corp_name + v.corp_id] = v.corp_id
					if (v.list.length) {
						forData(v.list, [v.corp_id])
					}
					if (i == 0) {
						let defaultCorp = {...corpTreeList}
						let keys = Object.keys(defaultCorp)
						this.defaultCorp = v.corp_id
					}
				})
				this.corpTreeList = corpTreeList
				this.corp_id = this.defaultCorp
				this.getTable()
			})
		},
		methods: {
			disabledDate (current) {
			  return current && current > moment().endOf('day')
			},
			tabelPageChange (page) {
				this.tablePage = page
				this.getTable()
			},
			onChange (data) {
				this.start_date = moment(data[0]).format('YYYY-MM-DD')
				this.end_date = moment(data[1]).format('YYYY-MM-DD')
			},
			corpChange(data) {
				this.corp_id = data
			},
			chcorpChange(data) {
				this.form.corp_id = data[data.length - 1]
			},
			btnClick(type) {
				if (type == '查询') {
					this.getTable()
				} else if (type == '重置') {
					this.corp_id = this.defaultCorp
					this.auditor_name = '' 
					this.start_date = ''
					this.end_date = ''
					this.tablePage = 1
					this.filterKey = Math.random()
					this.getTable()
				} else if (type == '刷新') {
					this.getTable()
				}
			},
			getTable() {
				http({
					method: 'post',
					url: '/api.auditor.audit.log/',
					data: {
						page: this.tablePage,
						auditor_name: this.auditor_name,
						start_date: this.start_date,
						end_date: this.end_date,
						corp_id: this.corp_id,
					}
				}).then((res) => {
					this.tableData = res.data.list
					this.tableTotal = res.data.total_count
				})
			},
			dcClick () {
				http({
					method: 'post',
					url: '/api.auditor.audit.export/',
					data: {
						auditor_name: this.auditor_name,
						start_date: this.start_date,
						end_date: this.end_date,
						corp_id: this.corp_id,
					}
				}).then((res) => {
					if (res.code == '200') {
						window.open(res.data)
					} else {
						if (res.code != '201') {
							this.$message.error(res.message);
						}
					}
				})
			}
		},
	};
</script>

<style lang="less" scoped>
	.table-pagination {
		text-align: right;
		padding: 20px 0px;
	}

	.filter-box {
		padding: 20px;
	}

	.btns10 {
		margin-left: 30px;

		button {
			margin-right: 10px;
		}
	}

	.table-box {
		padding: 0px 20px;
	}

	.ew-filter {
		display: flex;
		justify-content: space-between;
		padding: 0px 20px;
		padding-bottom: 20px;
		align-items: center;
	}
</style>