<template>
	<div class="content-box-div">
		<div class="filter-box">
			<a-form-model layout="inline">
				<a-form-model-item label="客户选择">
					<!-- <a-cascader style="width: 300px;"
						:fieldNames="{label: 'corp_name', value: 'corp_id', children: 'list' }" :key="corp_id"
						:defaultValue="corp_id" :options="corpList" :treeCheckStrictly="true" placeholder="请选择客户" @change="corpChange" /> -->
					<a-tree-select
					    v-model="corp_id"
						 @change="corpChange"
					   style="width: 300px;"
					    :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
					    :tree-data="corpList"
					    placeholder="请选择客户"
						:replaceFields="{children:'list', title:'corp_name', key:'corp_id', value: 'corp_id' }"
					  >
					  </a-tree-select>
				</a-form-model-item>
				<a-form-model-item label="账号">
					<a-input size="default" v-model="user_name" placeholder="输入账号查询">
					</a-input>
				</a-form-model-item>
				<a-form-model-item label="手机号码">
					<a-input size="default" v-model="mobile" placeholder="输入手机号码查询">
					</a-input>
				</a-form-model-item>
				<a-form-model-item label="设备编号">
					<a-input size="default" v-model="number" placeholder="输入设备编号查询">
					</a-input>
				</a-form-model-item>
				<a-form-model-item>
					<div class="btns10">

						<a-button type="primary" icon="search" @click="btnClick('查询')">
							查询
						</a-button>
						<a-button type="primary" icon="sync" @click="btnClick('重置')">
							重置
						</a-button>
						<a-button type="primary" icon="reload" @click="btnClick('刷新')">
							刷新
						</a-button>
					</div>
				</a-form-model-item>
			</a-form-model>
		</div>

		<div class="ew-filter">
			<div></div>
			<a-button type="primary" v-if="btnIds.includes(62)" @click="modelVisible = true" icon="plus-circle">
				添加
			</a-button>
		</div>

		<div class="table-box">
			<a-table :columns="columns" :data-source="tableData" :pagination="false">
				<a slot="name" slot-scope="text">{{ text }}</a>
				<div slot="age5">
					<span class="btn-span">正常</span>
				</div>
				<div slot="age6" slot-scope="text, record">			
					<span class="btn-span" v-if="btnIds.includes(157) && record.mtype==1" @click="bindDev(record)">
						存款设备
					</span>	
					<a-popconfirm placement="top" ok-text="确定" cancel-text="取消" @confirm="order(record.user_id,1)" v-if="btnIds.includes(67) && record.is_order_user==0">
						<template slot="title">
							<p>是否确定授权该操作员预约权限？</p>
						</template>
						<span class="btn-span">
							授权
						</span>
					</a-popconfirm>				
					<a-popconfirm placement="top" ok-text="确定" cancel-text="取消" @confirm="order(record.user_id,0)" v-if="btnIds.includes(67) && record.is_order_user>0">
						<template slot="title">
							<p>是否确定取消该操作员预约权限？</p>
						</template>
						<span class="btn-span">
							取消授权
						</span>
					</a-popconfirm>
					<span class="btn-span" v-if="btnIds.includes(65)" @click="climg(record)">
						绑定微信公众号
					</span>
					<span class="btn-span" v-if="btnIds.includes(63)" @click="edit(record)">
						编辑
					</span>
					<span class="btn-span" v-if="btnIds.includes(66)" @click="updatepwd(record.user_id)">
						修改密码
					</span>
					<a-popconfirm placement="top"  v-if="btnIds.includes(64)" ok-text="确定" cancel-text="取消" @confirm="remove(record.user_id)">
						<template slot="title">
							<p>是否确定删除该操作员？</p>
						</template>
						<span class="btn-span btn-span-delete">
							删除
						</span>
					</a-popconfirm>
				</div>
			</a-table>
			<div class="table-pagination">
				<a-pagination v-model="tablePage" @change="tabelPageChange" show-quick-jumper :total="tableTotal"
				:show-total="(total, range) => `共${total}条记录`"	:page-size="10" />
			</div>
		</div>

		<a-modal width="700px" :title="!form.user_id ? '添加' :'编辑'" :visible="modelVisible" @ok="handleOk" @cancel="handleCancel">
			<a-form-model :key="formKey" ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol"
				:wrapper-col="wrapperCol">
				<a-form-model-item label="操作模式" prop="mtype">
					<a-radio-group v-model="form.mtype">
						<a-radio :value="0">
							单例模式
						</a-radio>
						<a-radio :value="1">
							多例模式
						</a-radio>
					</a-radio-group>
				</a-form-model-item>
				<a-form-model-item label="选择客户" prop="corp_id">
					<a-tree-select v-model="form.corp_id" @change="chcorpChange" style="width: 100%;"
						:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" :tree-data="corpList"
						placeholder="请选择客户"
						:replaceFields="{children:'list', title:'corp_name', key:'corp_id', value: 'corp_id' }">
					</a-tree-select>
				</a-form-model-item>
				<a-form-model-item v-if="form.corp_id" label="选择网点" prop="node_id">
					<a-select style="width: 100%" v-model="form.node_id" placeholder="请选择网点">
						<a-select-option :value="item.node_id" v-for="(item, index) in nodeList" :key="index">
							{{item.name}}
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item v-if="form.corp_id && form.mtype == 0" label="选择设备" prop="device_id">
					<a-select style="width: 100%" v-model="form.device_id" placeholder="请选择设备">
						<a-select-option :value="item.id" v-for="(item, index) in deviceList" :key="index">
							{{item.name}}
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item v-if="form.corp_id" label="选择结算账户" prop="cba_id">
					<a-select style="width: 100%" v-model="form.cba_id" placeholder="请选择结算账户">
						<a-select-option :value="item.cba_id" v-for="(item, index) in accList" :key="index">
							{{item.bank}}-{{item.account}}
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item label="用户名" prop="user_name">
					<a-input v-model="form.user_name" placeholder="请输入用户名" />
				</a-form-model-item>
				<a-form-model-item label="密码" prop="password" v-if="!form.user_id">
					<a-input-password v-model="form.password" placeholder="请输入密码" />
				</a-form-model-item>
				<a-form-model-item label="确认密码" prop="npassword" v-if="!form.user_id">
					<a-input-password v-model="form.npassword" placeholder="请输入确认密码" />
				</a-form-model-item>
				<a-form-model-item label="姓名" prop="real_name" >
					<a-input v-model="form.real_name" placeholder="请输入姓名" />
				</a-form-model-item>
				<a-form-model-item label="手机号码" prop="mobile">
					<a-input v-model="form.mobile" placeholder="请输入手机号码" />
				</a-form-model-item>
				<a-form-model-item label="性别" prop="gender">
					<a-radio-group v-model="form.gender">
						<a-radio :value="1">
							男
						</a-radio>
						<a-radio :value="2">
							女
						</a-radio>
					</a-radio-group>
				</a-form-model-item>
				<a-form-model-item label="权限" prop="authority">
					<a-checkbox-group :options="optionsWithDisabled" v-model="form.authority">
						<span slot="label" slot-scope="{ value }" style="color: red">{{ value }}</span>
					</a-checkbox-group>
				</a-form-model-item>
				<a-form-model-item label="备注" prop="remark">
					<a-input v-model="form.remark" placeholder="请输入备注信息" />
				</a-form-model-item>
			</a-form-model>
		</a-modal>

		<a-modal title="修改操作员密码" :visible="modelPasswordVisible" @ok="handlePasswordOk" @cancel="handlePasswordCancel">
			<a-form-model ref="ruleForm" :model="passwordForm" :rules="passwordRules" :label-col="labelCol"
				:wrapper-col="wrapperCol">
				<a-form-model-item label="密码" prop="password">
					<a-input-password v-model="passwordForm.password" placeholder="请输入密码" />
				</a-form-model-item>
				<a-form-model-item label="确认密码" prop="npassword">
					<a-input-password v-model="passwordForm.npassword" placeholder="请输入确认密码" />
				</a-form-model-item>
			</a-form-model>
		</a-modal>
		
		<a-modal title="扫码绑定微信公众号" :visible="modelPasswordVisibleImg" @cancel="() => {modelPasswordVisibleImg = false}" width="300px" :footer="null">
			<img style="width: 200px;height: 200px;border: 1px solid #ccc;display: block;margin: 0 auto;" :src="imgsrc" alt="">
		</a-modal>
		
		<a-modal title="存款设备" width="700px" :visible="modelDevVisible" @ok="handleDevdOk" @cancel="modelDevVisible = false">
			<a-spin  :spinning="devspinning">
				<a-form-model ref="ruleForm" :model="passwordForm" :rules="passwordRules" :label-col="labelCol"
					:wrapper-col="wrapperCol">
					 <div class="mmmb">
						 <a-checkbox-group v-model="valDevList" :options="devList" @change="onChange" />
					 </div>
				</a-form-model>
			</a-spin>
		</a-modal>
		
	</div>
</template>

<script>
	import http from '@/http'
	const columns = [{
			title: '客户名称',
			dataIndex: 'corp_name',
			key: 'corp_name',
		},
		{
			title: '设备名称',
			key: 'name',
			dataIndex: 'name',
		},
		{
			title: '设备编号',
			key: 'number',
			dataIndex: 'number',
		},
		{
			title: '用户名',
			key: 'user_name',
			dataIndex: 'user_name',
		},
		{
			title: '姓名',
			key: 'real_name',
			dataIndex: 'real_name',
		},
		{
			title: '性别',
			key: 'gender_txt',
			dataIndex: 'gender_txt',
		},
		{
			title: '联系方式',
			key: 'mobile',
			dataIndex: 'mobile',
		},
		{
			title: '备注',
			key: 'remark',
			dataIndex: 'remark',
		},
		{
			title: '操作',
			key: 'age6',
			width: 420,
			scopedSlots: {
				customRender: 'age6'
			},
		},
	];
	export default {
		data() {
			return {
				modelVisible: false,
				columns,
				labelCol: {
					span: 6
				},
				wrapperCol: {
					span: 14
				},
				other: '',
				form: {
					corp_id: null,
					node_id: undefined,
					cba_id:undefined,
					device_id: undefined,
					user_name: '',
					password: '',
					npassword: '',
					real_name: '',
					remark:'',
					mobile: '',
					gender: 1,
					authority: null,
					user_id: 0,
					mtype: 0
				},
				rules: {
					mtype: [{
						required: true,
						message: '请选择操作方式',
						trigger: 'change'
					}],
					user_name: [{
						required: true,
						message: '请输入用户名',
						trigger: 'blur'
					}],
					password: [{
						required: true,
						message: '请输入密码',
						trigger: 'blur'
					}, {
						min: 6,
						max: 20,
						message: '密码最小长度为6位数',
						trigger: 'blur'
					}],
					npassword: [{
						required: true,
						message: '请输入确定密码',
						trigger: 'blur'
					}, {
						min: 6,
						max: 20,
						message: '密码最小长度为6位数',
						trigger: 'blur'
					}],
					real_name: [{
						required: true,
						message: '请输入姓名',
						trigger: 'blur'
					}],
					mobile: [{
						required: true,
						message: '请输入手机号码',
						trigger: 'blur'
					}],
					corp_id: [{
						required: true,
						message: '请选择客户',
						trigger: 'change'
					}],
					node_id: [{
						required: true,
						message: '请选择网点',
						trigger: 'change'
					}],
					cba_id: [{
						required: true,
						message: '请选择结算账号',
						trigger: 'change'
					}],
					device_id: [{
						required: true,
						message: '请选择设备',
						trigger: 'change'
					}],
					authority: [{
						required: true,
						message: '请选择权限',
						trigger: 'change'
					}],
				},

				user_name: '',
				mobile: '',
				number: '',
				corp_id: null,
				tableData: [],
				tableTotal: 0,
				tablePage: 1,
				corpList: [],
				corpTreeList: [],
				defaultCorp: [],
				formKey: Math.random(),

				nodeList: [],
				accList:[],
				deviceList: [],

				modelPasswordVisible: false,
				passwordForm: {
					user_id: 0,
					password: '',
					npassword: ''
				},
				passwordRules: {
					password: [{
						required: true,
						message: '请输入密码',
						trigger: 'blur'
					}, {
						min: 6,
						max: 20,
						message: '密码最小长度为6位数',
						trigger: 'blur'
					}],
					npassword: [{
						required: true,
						message: '请输入确认密码',
						trigger: 'blur'
					}, {
						min: 6,
						max: 20,
						message: '密码最小长度为6位数',
						trigger: 'blur'
					}],
				},
				optionsWithDisabled: [{label: '存款', value: 'deposit'}, {label: '查询', value: 'account'}, {label: '清机', value: 'clean'}, {label: '设置', value: 'setting'}],
				imgsrc: '',
				modelPasswordVisibleImg: false,
				
				btnIds: localStorage.getItem('btn_ids') ? JSON.parse(localStorage.getItem('btn_ids')) : [],
				
				modelDevVisible: false,
				devList: [],
				valDevList: [],
				devUser_id: 0,
				devspinning: true
			};
		},
		mounted() {
			http({
				method: 'post',
				url: '/api.corp.treelist/',
				data: {}
			}).then((res) => {
				this.corpList = res.data
				let corpTreeList = {}

				function forData(data, ids) {
					data.forEach((v) => {
						corpTreeList[v.corp_name + v.corp_id] = [...ids, v.corp_id]
						if (v.list.length) {
							forData(v.list, [...ids, v.corp_id])
						}
					})
				}
				res.data.forEach((v, i) => {
					corpTreeList[v.corp_name + v.corp_id] = v.corp_id
					if (v.list.length) {
						forData(v.list, [v.corp_id])
					}
					if (i == 0) {
						let defaultCorp = {
							...corpTreeList
						}
						let keys = Object.keys(defaultCorp)
						this.defaultCorp = v.corp_id
					}
				})
				this.corpTreeList = corpTreeList
				this.corp_id = this.defaultCorp
				this.getTable()
			})
		},
		methods: {
			handleDevdOk () {
				http({
					method: 'post',
					url: '/api.user.todevice/',
					data: {
						user_id: this.devUser_id,
						device_id: this.valDevList.join()
					}
				}).then((res) => {
					if (res.code == "200") {
						this.$message.success('设置存款设备成功');
						this.getTable()
						this.modelDevVisible = false
					} else {
						this.$message.warning(res.message);
					}
				})
			},
			bindDev (data) {
				this.devspinning = true
				this.getDevList(data)
				this.modelDevVisible = true
			},
			handleChange(value) {
			      console.log(`selected ${value}`);
			},
			getDevList (data) {
				http({
					method: 'post',
					url: '/api.device.treelist/',
					data: {
						corp_id: data.corp_id
					}
				}).then((res) => {
					this.devList = res.data.map((v) => {
						return { label: v.name, value: String(v.id) }
					})
					this.valDevList = data.device_id_list
					this.devUser_id = data.user_id
					this.devspinning = false
				})
			},
			climg (data) {
				this.imgsrc = data.qrcode
				this.modelPasswordVisibleImg = true
			},
			handleSubmit(e) {
			},
			corpChange(data) {
				this.corp_id = data
			},
			chcorpChange(data) {
				this.form.corp_id = data
				this.form.node_id = undefined
				this.form.device_id = undefined
				this.getAccList(this.form.corp_id)
				this.getNodeList(this.form.corp_id)
			},
			getNodeList(corp_id) {
				http({
					method: 'post',
					url: '/api.corp.node.treelist/',
					data: {
						corp_id: corp_id
					}
				}).then((res) => {
					this.nodeList = res.data
				})
				http({
					method: 'post',
					url: '/api.device.treelist/',
					data: {
						corp_id: corp_id
					}
				}).then((res) => {
					this.deviceList = res.data
				})

			},
			getAccList(corp_id) {
				http({
					method: 'post',
					url: '/api.corp.acc.list/',
					data: {
						corp_id: corp_id
					}
				}).then((res) => {
					this.accList = res.data
				})
			},
			getDeviceList() {
				http({
					method: 'post',
					url: '/api.corp.node.treelist/',
					data: {
						corp_id: corp_id
					}
				}).then((res) => {
					this.nodeList = res.data
				})
			},
			btnClick(type) {
				if (type == '查询') {
					this.getTable()
				} else if (type == '重置') {
					this.corp_id = this.defaultCorp
					this.user_name = ''
					this.mobile = ''
					this.number = ''
					this.tablePage = 1
					this.getTable()
				} else if (type == '刷新') {
					this.getTable()
				}
			},
			getTable() {
				http({
					method: 'post',
					url: '/api.user.list/',
					data: {
						user_name: this.user_name,
						mobile: this.mobile,
						number: this.number,
						page: this.tablePage,
						corp_id: this.corp_id
					}
				}).then((res) => {
					this.tableData = res.data.list
					this.tableTotal = res.data.total_count
				})
			},
			tabelPageChange(page) {
				this.tablePage = page
				this.getTable()
			},
			remove(id) {
				http({
					method: 'post',
					url: '/api.user.del/',
					data: {
						user_id: id,
					}
				}).then((res) => {
					if (res.code == "200") {
						this.$message.success('删除操作员成功');
						this.getTable()
					} else {
						this.$message.warning(res.message);
					}
				})
			},
			order(id,status) {
				http({
					method: 'post',
					url: '/api.user.toorder/',
					data: {
						user_id: id,
						status: status,
					}
				}).then((res) => {
					if (res.code == "200") {
						this.$message.success('操作员授权操作成功');
						this.getTable()
					} else {
						this.$message.warning(res.message);
					}
				})
			},
			edit(data) {
				this.getNodeList(data.corp_id)
				this.getAccList(data.corp_id)
				http({
					method: 'post',
					url: '/api.user.detail/',
					data: {
						user_id: data.user_id
					}
				}).then((res) => {
					if (res.code == "200") {
						let cp = this.corpTreeList[data.corp_name + data.corp_id]
						this.form = {
							...res.data,
							corp_id: typeof cp == 'number' ? cp : cp[cp.length - 1]
						}
						this.modelVisible = true;
						this.formKey = Math.random()
					} else {
						this.$message.warning(res.message);
					}
				})
				// let pid = this.form.corp_id[this.form.corp_id.length - 1]
				// this.getNodeList(pid)
				
			},

			handleOk() {
				this.$refs.ruleForm.validate(valid => {
					if (valid) {
						let newData = {
							...this.form,
							authority: this.form.authority.join(','),
							device_id: this.form.mtype == 1 ? 0 : this.form.device_id
						}
						delete newData.corp_name
						if (typeof newData.corp_id == 'object') {
							newData.corp_id = newData.corp_id[newData.corp_id.length - 1]
						}
						if (!this.form.user_id) {
							if (this.form.password !== this.form.npassword) {
								this.$message.warning('两次密码不相同，请检查');
								return
							}
							http({
								method: 'post',
								url: '/api.user.add/',
								data: newData
							}).then((res) => {
								if (res.code == "200") {
									if (this.form.user_id) {
										this.$message.success('编辑操作员信息成功');
									} else {
										this.$message.success('添加操作员成功');
									}
									this.getTable()
									this.modelVisible = false;
									this.initFormData()
								} else {
									this.$message.warning(res.message);
								}
							})
						} else {
							http({
								method: 'post',
								url: '/api.user.edit/',
								data: newData
							}).then((res) => {
								if (res.code == "200") {
									if (this.form.user_id) {
										this.$message.success('编辑操作员信息成功');
									} else {
										this.$message.success('添加操作员成功');
									}
									this.getTable()
									this.modelVisible = false;
									this.initFormData()
								} else {
									this.$message.warning(res.message);
								}
							})
						}
					} else {
						return false;
					}
				});

			},
			handleCancel() {
				this.modelVisible = false;
				this.initFormData()
			},
			initFormData() {
				this.form = {
					corp_id: null,
					node_id: undefined,
					cba_id: undefined,
					device_id: undefined,
					user_name: '',
					password: '',
					npassword: '',
					real_name: '',
					remark: '',
					mobile: '',
					gender: 1,
					authority: null,
					user_id: 0,
					mtype: 0
				}
				this.formKey = Math.random()
			},

			handlePasswordOk() {
				this.$refs.ruleForm.validate(valid => {
					if (valid) {
						if (this.passwordForm.password !== this.passwordForm.npassword) {
							this.$message.warning('两次密码不相同，请检查');
							return
						}
						let newData = {
							...this.passwordForm
						}
						http({
							method: 'post',
							url: '/api.user.updatepwd/',
							data: newData
						}).then((res) => {
							if (res.code == "200") {
								this.$message.success('重置操作员密码成功');
								this.getTable()
								this.modelPasswordVisible = false;
								this.passwordForm = {
									admin_id: 0,
									password: '',
									npassword: ''
								}
							} else {
								this.$message.warning(res.message);
							}
						})
					} else {
						return false;
					}
				});

			},
			updatepwd(user_id) {
				this.modelPasswordVisible = true
				this.passwordForm = {
					user_id: user_id,
					password: '',
					npassword: ''
				}
			},
			handlePasswordCancel() {
				this.modelPasswordVisible = false;
				this.passwordForm = {
					user_id: 0,
					password: '',
					npassword: ''
				}
			},
		},
	};
</script>

<style lang="less" scoped>
	.table-pagination {
		text-align: right;
		padding: 20px 0px;
	}

	.filter-box {
		padding: 20px;
	}

	.btns10 {
		margin-left: 30px;

		button {
			margin-right: 10px;
		}
	}

	.table-box {
		padding: 0px 20px;
	}

	.ew-filter {
		display: flex;
		justify-content: space-between;
		padding: 0px 20px;
		padding-bottom: 20px;
		align-items: center;
	}
</style>